import React, {useState, useEffect} from "react"
import {Link} from "react-router-dom"
import {MDBBtn} from "mdb-react-ui-kit"

import "./commonStyles.css"

const Alert = ({mesg, className}) => {
  const [close, setClose] = useState(true)

  const handleClose = () => {
    setClose(!close)
    console.log("close", close)
  }

  return (
    <>
      {close ? (
        <div className="alertStyles" >
          <div className={className}> {mesg} </div>
          <div className="closeAleft" onClick={handleClose}>
            <MDBBtn className="btn-close" color="none" aria-label="Close" />
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default Alert
