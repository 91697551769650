import React, { useState, useEffect } from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import APIService from '../../Services/APIService';
import Alert from '../../common/Alert';

const AddEditRoad = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);
  const { facilityId, selectedRoad, selectedBlock } = location.state;
  const fac = facilities?.filter(f => f._id == facilityId);
  const facility = fac.length > 0 ? fac[0] : {}
  const [formValue, setFormValue] = useState({ roadName: selectedRoad?.road_name ?  selectedRoad?.road_name : '' });
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const saveRoad = async (e) => {
    e.preventDefault();
    if (!formValue.roadName) return;
    let nameExist = facility?.roads && facility?.roads?.filter(r => r.road_name == formValue.roadName);
    console.log(nameExist);
    if (nameExist?.length > 0) {
      setAlert({ show: true, message: "Duplicate Road name entered.. Try with other Name..", className: "warning" });
        setTimeout(() => { setAlert({ show: false, message: "", className: "" }) }, 1500);
        //alert('Duplicate Road name entered.. Try with other Name..');
      return;
    }
    if (formValue.roadName && selectedRoad?._id) {
        updateRoad();
    } else {
        createRoad();
    }
  };

  const updateRoad = async () => {
    if (formValue.roadName && selectedRoad?._id) {
      console.log('call for update');
      let payload = {
        "id":selectedRoad?._id,
        "road_name": formValue.roadName,
        "block_id": selectedBlock?._id
      }
      APIService.updateRoad(payload).then((res => {
        setAlert({ show: true, message: "Updated Successlly..", className: "success" });
        setTimeout(() => {
          navigate(-1)
          setAlert({ show: false, message: "", className: "" })
        },
          1500);
      }), ((error) => {
        console.log("error::", error)
      }))

    }
  };

  const createRoad = async () => {
    if (formValue.roadName) {
      let payload = {
        "facility_id": facilityId,
        "road_name": formValue.roadName,
        "block_id": selectedBlock?._id
      }
      APIService.saveRoad(payload).then((res => {
        setAlert({ show: true, message: "Successlly Saved..", className: "success" });
        setTimeout(() => {
          navigate(-1)
          setAlert({ show: false, message: "", className: "" })
        },
          1500);
      }), ((error) => {
        console.log("error::", error)
      }))

    }
  };

  return (
    <div className="p-5">
      {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
      <MDBValidation className='row g-3' onSubmit={saveRoad}>
        <MDBValidationItem className='col-md-12' feedback='Please enter Block name.' invalid>
          <MDBInput
            value={formValue.roadName}
            name='roadName'
            onChange={onChange}
            id='validationCustom03'
            required
            label='Road Name'
          />
        </MDBValidationItem>

        <div className="text-center">
          <MDBBtn type='submit'>Submit form</MDBBtn>
        </div>
      </MDBValidation>
    </div>
  )
}

export default AddEditRoad
