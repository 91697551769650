import React from "react"
import {useNavigate} from "react-router-dom"

import "./CreateAccount.css"

const CreateAccount = () => {
  const navigate = useNavigate()

  const routeChange = () => {
    let path = `/MainPage`
    navigate(path)
  }

  return (
    <div
      className="container d-flex flex-column justify-content-center align-items-center px-5 createAccount"
      style={{height: "88vh"}}
    >
      <h2 className="title fw-bold fs-4">Create Account</h2>

      <form className="mt-4 mb-5">
        <div className="form-outline mb-3">
          <i className="fas fa-user fs-3 trailing"></i>
          <input
            type="text"
            id="form12"
            className="form-control form-icon-trailing p-2"
          />
          <label className="form-label" htmlFor="form12">
            First Name
          </label>
        </div>
        <div className="form-outline mb-3">
          <i className="fas fa-user fs-3 trailing"></i>
          <input
            type="text"
            id="form12"
            className="form-control form-icon-trailing p-2"
            style={{border: "1px solid #ddd"}}
          />
          <label className="form-label" htmlFor="form12">
            Last Name
          </label>
        </div>
        <div className="form-outline mb-3">
          <i className="fas fa-envelope fs-3 trailing"></i>
          <input
            type="email"
            id="form12"
            className="form-control form-icon-trailing p-2"
            style={{border: "1px solid #ddd"}}
          />
          <label className="form-label" htmlFor="form12">
            Email
          </label>
        </div>
        <div className="form-outline mb-3">
          <i className="fas fa-key fs-3 trailing"></i>
          <input
            type="password"
            id="form12"
            className="form-control form-icon-trailing p-2"
            style={{border: "1px solid #ddd"}}
          />
          <label className="form-label" htmlFor="form12">
            Password
          </label>
        </div>
        <div className="form-outline mb-3">
          <i className="fas fa-key fs-3 trailing"></i>
          <input
            type="password"
            id="form12"
            className="form-control form-icon-trailing p-2"
            style={{border: "1px solid #ddd"}}
          />
          <label className="form-label" htmlFor="form12">
            Confirm Password
          </label>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block p-3 rounded-pill fs-6"
          onClick={routeChange}
        >
          Sign Up
        </button>
      </form>
    </div>
  )
}

export default CreateAccount
