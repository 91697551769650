import userProfile from "../common/userProfile";

export function getPermission(facilityId, permission) {
  let user = userProfile.getUserObj();
  //console.log('user1234', user);
  const facilities = user?.user_facilities?.filter((f) => f.facility_id === facilityId);
  const permissions = facilities[0]?.roles[0]?.permissions;
  if (permissions) {
    const res = Object.fromEntries(Object.entries(permissions).filter(([key, value]) => key === permission));
    return res[permission];
  } else {
    return false;
  }

}
