class userProfile {

    static userobj = {};

    static facilityobj = {};

    static facilityBlocks = [];

    static facilities = [];

    static setUserObj(inpobj) {
        this.userobj = inpobj;
        window.localStorage.setItem('userData', JSON.stringify(inpobj));
    }

    static getUserObj() {
        if (this.userobj && this.userobj?.user_id) {
            return this.userobj;
        } else {
            let userobj = window.localStorage.getItem('userData');
            if (userobj) {
                userobj = JSON.parse(userobj)
            }
            this.userobj = userobj || null;
            return userobj;
        }
    }

    static setFacility(inpobj) {
        this.facilityobj = inpobj;
        window.localStorage.setItem('facilityObj', JSON.stringify(inpobj));
        window.localStorage.setItem('facilityId', JSON.stringify(inpobj._id));
    }

    static getFacilityId() {
        if (this.facilityobj && this.facilityobj._id) {
            return this.facilityobj._id;
        } else {
            let facilityId = window.localStorage.getItem('facilityId');
            if (facilityId) {
                facilityId = JSON.parse(facilityId)
            }
            //this.facilityobj = facilityobj._ || null;
            return facilityId;
        }
    }

    static getFacility() {
        if (this.facilityobj && this.facilityobj._id) {
            return this.facilityobj;
        } else {
            let facilityobj = window.localStorage.getItem('facilityObj');
            if (facilityobj) {
                facilityobj = JSON.parse(facilityobj)
            }
            this.facilityobj = facilityobj || null;
            return facilityobj;
        }
    }

    static setFacilityBlocks(blocks) {
        this.facilityBlocks = blocks;
        window.localStorage.setItem('facilityBlocks', JSON.stringify(blocks));
    }

    static getFacilityBlocks() {
        if (this.facilityBlocks && this.facilityBlocks.length > 0) {
            return this.facilityBlocks;
        } else {
            let facilityBlocks = window.localStorage.getItem('facilityBlocks');
            if (facilityBlocks?.length > 0) {
                facilityBlocks = JSON.parse(facilityBlocks)
            }
            this.facilityBlocks = facilityBlocks || null;
            return facilityBlocks;
        }
    }

    static debounce(func, timeout = 1000) {
        let timer;
        return (...args) => {
            clearTimeout(timer);
            timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    static setFacilities(inpobj) {
        this.facilities = inpobj;
        window.localStorage.setItem('facilityData', JSON.stringify(inpobj));
    }

    static getFacilities() {
        if (this.facilities && this.facilities.length > 0) {
            return this.facilities;
        } else {
            let facilities = window.localStorage.getItem('facilityData');
            if (facilities?.length > 0) {
                facilities = JSON.parse(facilities)
            }
            this.facilities = facilities || null;
            return facilities;
        }
    }
}

export default userProfile;