import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MDBContainer } from "mdb-react-ui-kit";

import Tile from "./Tile"
import Title from "../common/Title"
import userProfile from '../common/userProfile';
import { updateFacility, updateFacilityId } from "../../redux-store/appSlice";

import "./Rentsale.css"

const RentSale = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);

  const goToProperties = (facilityObj) => {
    userProfile.setFacility(facilityObj);
   // dispatch(updateFacility(facilityObj));
    dispatch(updateFacilityId(facilityObj?._id));
    navigate('/Properties');
  }

  return (
    <div className="p-2">
      <Title title="My Facilities" />
      <MDBContainer className="rentSale">
        {facilities && facilities.length > 0 &&
          facilities.map((item) => (
            <div className="facilities" key={item._id} onClick={() => goToProperties(item)}>
              <Tile key={item._id} item={item} />
            </div>
          ))}

        {/* <MDBCol className="tile p-2 mx-1 d-flex align-items-center justify-content-center rentSaleViewMore">
      <div className="p-2">
          <a href="" className="m-0"> VIEW MORE </a>
      </div>
    </MDBCol> */}
      </MDBContainer>
    </div>
  )
}

export default RentSale
