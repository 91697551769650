import React from "react"

const Roads = ({ roadList, setSelectedRoadId, roadId }) => {

  const onSelectRoad = (id) => {
    setSelectedRoadId(id);
  }

  return (
    <div className="roads">
       <span className={roadId == 0 ? 'selectedBlock' : ''} onClick={() => onSelectRoad(0)}>All</span>
      {roadList && roadList.map(road => (
        <span key={road._id} onClick={() => onSelectRoad(road._id)} className={roadId == road._id ? 'selectedBlock' : ''} >
          {road.road_name}
        </span>
      ))}
      {/* <span>Road 1</span>
      <span>Road 2</span>
      <span>Road 3</span>
      <span>Road 4</span>
      <span>Road 5</span>
      <span>Road 6</span> */}
    </div>
  )
}

export default Roads
