export default class Permission {

    static block_create = 'block_create';
    static block_delete = 'block_delete';
    static block_edit = 'block_edit';
    static block_list = 'block_list';
    static block_view = 'block_view';

    static road_create = 'road_create';
    static road_delete = 'road_delete';
    static road_edit = 'road_edit';
    static road_list = 'road_list';
    static road_view = 'road_view';

    static society_bank_create = 'society_bank_create';
    static society_bank_delete = 'society_bank_delete';
    static society_bank_edit = 'society_bank_edit';
    static society_bank_list = 'society_bank_list';
    static society_bank_view = 'society_bank_view';

    static society_create = 'society_create';
    static society_delete = 'society_delete';
    static society_edit = 'society_edit';
    static society_list = 'society_list';
    static society_view = 'society_view';

    static unit_create = 'unit_create';
    static unit_delete = 'unit_delete';
    static unit_edit = 'unit_edit';
    static unit_list = 'unit_list';
    static unit_view = 'unit_view';

    static unit_type_create = 'unit_type_create';
    static unit_type_delete = 'unit_type_delete';
    static unit_type_edit = 'unit_type_edit';
    static unit_type_list = 'unit_type_list';
    static unit_type_view = 'unit_type_view';

    
}
