import React from "react"
import Tile from "./Tile"
import Title from "../common/Title"
import {MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit"

import "./Approvals.css"

const Approvals = () => {
  const data = [
    {
      id: 1,
      price: "15,000.00",
      description: "Lorem ipsum lorem",
      bgColor: "#ff928e",
    },
    {
      id: 2,
      price: "28,000.00",
      description: "Lorem ipsum lorem",
      bgColor: "#939df2",
    },
    {
      id: 3,
      price: "31,000.00",
      description: "Lorem ipsum lorem",
      bgColor: "#49d391",
    },
    {
      id: 4,
      price: "31,000.00",
      description: "Lorem ipsum lorem",
      bgColor: "#ff928e",
    },
    {
      id: 5,
      price: "31,000.00",
      description: "Lorem ipsum lorem",
      bgColor: "#49d391",
    },
  ]

  return (
    <div className="p-2">
      <Title title="APPROVALS" />
      <MDBContainer className="approvals d-flex">
        {data && data.map((item) => <Tile key={item.id} item={item} />)}

        <MDBCol className="tile p-2 mx-1 d-flex align-items-center justify-content-center approvalsViewMore">
          <div className="p-2">
            <a href="" className="m-0">
              {" "}
              VIEW MORE{" "}
            </a>
          </div>
        </MDBCol>
      </MDBContainer>
    </div>
  )
}

export default Approvals
