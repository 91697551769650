import { createSlice } from "@reduxjs/toolkit";

const initial_state = {
  userData: {}
};
const userSlice = createSlice({
  name: "app/data",
  initialState: initial_state,
  reducers: {
    updateUserData: (state, action) => {
      return { ...state, userData: action.payload };
    },
  },
});
export const { updateUserData } = userSlice.actions;
export default userSlice.reducer;
