import React, { useState, useEffect } from "react";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';
import Alert from '../../common/Alert';
import { useNavigate, useLocation } from "react-router-dom";
import APIService from '../../Services/APIService';
import { useSelector } from "react-redux";

const AddEditBlock = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { facilityId, selectedBlock } = location.state;
  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);
  const fac = facilities.filter(f => f._id == facilityId);
  const facility = fac.length > 0 ? fac[0] : {}
  
  const [formValue, setFormValue] = useState({ blockName: selectedBlock?.block_name ? selectedBlock?.block_name : '' });
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const saveBlock = async (e) => {
    e.preventDefault();
    if (!formValue.blockName) return;
    let nameExist = facility?.blocks && facility?.blocks.filter(block => block.block_name == formValue.blockName);
    //console.log(nameExist);
    if (nameExist?.length > 0) {
      setAlert({ show: true, message: "Duplicate Block name entered.. Try with other Name..", className: "warning" });
        setTimeout(() => { setAlert({ show: false, message: "", className: "" }) }, 1500);
      //alert('Duplicate Block name entered.. Try with other Name..');
      return;
    }
    if (formValue.blockName && selectedBlock?._id) {
      updateBlock();
    } else {
      createBlock();
    }
  };

  const updateBlock = async () => {
    if (formValue.blockName && selectedBlock?._id) {
      console.log('call for update');
      let payload = {
        "id": selectedBlock?._id,
        "block_name": formValue.blockName
      }
      APIService.updateBlock(payload).then((res => {
        console.log(res)
        setAlert({ show: true, message: "Updated Successlly..", className: "success" });
        setTimeout(() => {
          navigate(-1)
          setAlert({ show: false, message: "", className: "" })
        },
          1500);
      }), ((error) => {
        console.log("error::", error)
      }))

    }
  };

  const createBlock = async () => {
    if (formValue.blockName) {
      let payload = {
        "facility_id": facilityId,
        "block_name": formValue.blockName
      }
      APIService.saveBlock(payload).then((res => {
        console.log(res)
        setAlert({ show: true, message: "Successlly Saved..", className: "success" });
        setTimeout(() => {
          navigate(-1)
          setAlert({ show: false, message: "", className: "" })
        },
          1500);
      }), ((error) => {
        console.log("error::", error)
      }))
    }
  };


  return (
    <div className="p-5">
       {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
      <MDBValidation className='row g-3' onSubmit={saveBlock}>
        <MDBValidationItem className='col-md-12' feedback='Please enter Block name.' invalid>
          <MDBInput
            value={formValue.blockName}
            name='blockName'
            onChange={onChange}
            id='validationCustom03'
            required
            label='Block Name'
          />
        </MDBValidationItem>

        <div className="text-center">
          <MDBBtn type='submit'>Save</MDBBtn>
        </div>
      </MDBValidation>
    </div>
  )
}

export default AddEditBlock
