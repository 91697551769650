import React from "react";
import { MDBRow, MDBCol, MDBSpinner } from "mdb-react-ui-kit";

function Loader() {

  return (
          <div className='d-flex justify-content-center align-items-center' style={{ height: "88vh" }}>
            <MDBSpinner grow size='sm'>
              <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
            <MDBSpinner grow className='mx-2' size='sm'>
              <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
            <MDBSpinner grow size='sm'>
              <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
          </div> 
  );
}

export default Loader;
