import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase';

function joinURL(baseUrl, url) {
    return `${baseUrl}/${url}`;
}

function getHeaders() {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
    onAuthStateChanged(auth, (currentuser) => {
        console.log('currentuser', currentuser);
        if (currentuser) {
            headers.Authorization = 'Bearer ' + currentuser.accessToken
        }
    })
    return headers;
}
function handleErrors(response) {
    if (!response.ok) {
        if (response.status === 404) {
            //   alert('Data not found ' + response.statusText);
                throw Error('URL not found ' + response.statusText);
        }else if (response.status === 400 ) {
            //   alert('Data not found ' + response.statusText);
                throw Error('Data not found ' + response.statusText);
        }else if(response.status === 500 || response.status === 502){
            throw Error('Internal Server error ' + response.statusText);
        }
         else {
            //   alert('Internal server error ' + response.statusText);
            //   throw Error('Internal server error ' + response.statusText);
        }
    }
    return response;
}

class HttpService {
    static apiurl = process.env.REACT_APP_API_URL;

    static async request(url, options) {
        options.headers = await getHeaders();

        return new Promise((resolve, reject) => {
            return fetch(url, options)
                .then(handleErrors)
                .then(res => res.json())
                .then(responsejson => {
                    resolve(responsejson)
                })
                .catch((error) => {
                    //console.log(error)
                    reject(error)
                });
        })
    }

    static get(url, params) {
        url = joinURL(this.apiurl, url);
        if (params) url += '?' + (new URLSearchParams(params)).toString();
        const options = {
            method: 'GET'
        };
        return this.request(url, options);
    }

    static post(url, data) {
        url = joinURL(this.apiurl, url);
        const options = {
            method: 'POST'
        };

        if (data) {
            options.body = JSON.stringify(data)
        }

        return this.request(url, options);
    }

    static put(url, data) {
        url = joinURL(this.apiurl, url);
        const options = {
            method: 'PUT'
        };

        if (data) {
            options.body = JSON.stringify(data)
        }

        return this.request(url, options);
    }

    static patch(url, data) {
        url = joinURL(this.apiurl, url);
        const options = {
            method: 'PATCH'
        };

        if (data) {
            options.body = JSON.stringify(data)
        }

        return this.request(url, options);
    }

    static delete(url, data) {
        url = joinURL(this.apiurl, url);
        const options = {
            method: 'DELETE'
        };

        if (data) {
            options.body = JSON.stringify(data)
        }

        return this.request(url, options);
    }

    static getUser(url) {
        const options = {
            method: 'GET'
        };
        return this.newRequest(url, options);
    }

    static async newRequest(url, options) {
        options.headers = await getHeaders();
        return new Promise((resolve, reject) => {
            return fetch(url, options)
                //.then(handleErrors)
                .then(res => res.json())
                .then(responsejson => {
                    //console.log('responsejson', responsejson)
                    resolve(responsejson)
                })
                .catch((error) => {
                    //console.log(error)
                    reject(error)
                });
        })
    }
}

export default HttpService;