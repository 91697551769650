import React from "react"
import {MDBBtn, MDBIcon, MDBRow, MDBCol} from "mdb-react-ui-kit"

const Tile = ({item, type}) => {
  return (
    <MDBCol className={`tile p-2 mx-1`} style={{background: `${item.bgColor}`}}>
      <div className="d-flex">
        <div className="flex-grow-1">
          <h6>{item.roomno}</h6>
          <h6 className="m-0 fw-light"> {item.ownerTitle} </h6>
          <small> {item.tenentTitle} </small>
        </div>
        <div className="ms-2 visitorInfo flex-grow-1">
          <h6>{item.due}</h6>
          <h6 className="m-0 fw-light"> {item.owner} </h6>
          <small> {item.tenent} </small>
        </div>
      </div>
    </MDBCol>
  )
}

export default Tile
