import React, { useEffect, useState, Fragment } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import { useSelector, useDispatch } from "react-redux";

// Component Import's
import Title from "../../../common/Title";
import APIService from "../../../Services/APIService";
import Loader from "../../../common/Loader";
import Alert from "../../../common/Alert";
import { getPermission } from "../../../Services/PermissionUtil";
import Permission from "../../../Services/Permission";

// CSS Import's
import "./AddEditPropertyForm.css";
import { getFacilityData } from "../../../../redux-store/facilitySlice";

const AddEditPropertyForm = ({ title, userId = "Owner" }) => {

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { property } = location.state;
  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);
  const facilityId = useSelector((state) => state?.appSlice?.facilityId);
  const fac = facilities?.filter(f => f._id == facilityId);
  const facility = fac.length > 0 ? fac[0] : {}

  console.log(property, 'facilityId', facilityId)

  //const blocks = useSelector((state) => state?.appSlice?.blocks);
  
  const [blocks, setBlocks] = useState([]);
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState(blocks.length > 0 ? blocks[0] : {})
  const [roads, setRoads] = useState([]);
  const [selectedRoad, setSelectedRoad] = useState({})
  const [facilityTypes, setFacilityTypes] = useState([]);
  const [facilityUnitTypes, setUnitTypes] = useState([]);
  const [selectedUnitType, setSelectedUnitType] = useState({})
  const [unitUserTypes, setUnitUserTypes] = useState([]);
  const [selecteduserType, setUserType] = useState({});


  const [unitData, setUnitData] = useState({});

  const [tenentExist, setTenentExist] = useState(false);
  const [careTakerExist, setCareTakerExist] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [userObj, setUserobj] = useState({});
  const blockCreate = getPermission(facility?._id, Permission.block_create);
  const blockUpdate = getPermission(facility?._id, Permission.block_edit);
  const unitCreate = getPermission(facility?._id, Permission.unit_create);
  const unitUpdate = getPermission(facility?._id, Permission.unit_edit);
  console.log('blockCreate', blockCreate)

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });

  const [formValue, setFormValue] =
    useState({
      blockId: '',
      roadId: '',
      unitTypeId: '',
      name: '',
      email: '',
      mobile: '',
      extension: '+91',
      firstName: '',
      lastName: '',
      unitUserTypeId: '',
      unitUserId: '',
      gender:''
    });

  // const getRoadsCall = (blockId) => {
  //   let payload = {
  //     "facilityId": facility.id,
  //     "blockId": blockId
  //   }
  //   console.log('payload', payload)
  //   APIService.getRoads(facility.id).then((res => {
  //     setRoads(res);
  //     setSelectedRoad(res[0])
  //     //setFormValue({ ...formValue, roadId: id });
  //     onSelectRoad(res[0]?.id);
  //   }), ((error) => {
  //     setRoads([]);
  //     console.log("error::", error)
  //   }))
  // };


  const bindForm = (userObj, res) => {
    //console.log(userObj,"userobj123")
    //console.log(res,"unit123")
    let stateObj = {
      unitTypeId: res?.unit_type_id ? res?.unit_type_id : '',
      blockId: res?.block_id ? res.block_id : '',
      roadId: res?.road_id ? res.road_id : '',
      name: res?.unit_name ? res.unit_name : '',
      email: userObj?.email ? userObj.email : '',
      gender: userObj?.gender ? userObj.gender : 'male',
      mobile: userObj?.mobile_no ? userObj.mobile_no : '',
      extension: userObj?.country_code ? userObj.country_code : '+91',
      firstName: userObj?.display_name ? userObj.display_name : '',
      lastName: userObj?.last_name ? userObj.last_name : '',
      unitUserTypeId: res?.prefredUserId ? res?.prefredUserId : '',
      unitUserId: userObj?._id ? userObj._id : '',
    };

    setFormValue(stateObj);

  }

  const getUnitDetails = () => {
    let userArr = [];
    setUnitData(property);
    onSelectBlock(property?.blockId);
    onSelectRoad(property?.roadId);
    onSelectUnitType(property?.unit_type_id);
    let userIndex = property?.user?.map(i => i.user_type).indexOf(userId) ?? -1;
    let userObj = property?.user[userIndex] ;
    console.log(userObj, 'userIndex', userIndex, 'userId', userId);
    setUserobj(userObj)
    bindForm(userObj, property)

    let tenentArr = property?.user?.filter(t => t.user_type == 'Tenant');
    if (tenentArr?.length > 0) setTenentExist(true);

    let careTakerArr = property?.user?.filter(t => t.user_type == 'Caretaker');
    if (careTakerArr?.length > 0) setCareTakerExist(true);

    property?.user && property?.user?.length > 0 && property?.user.map(user => {
      let userObj = { userType: user.user_type, userTypeId: user._id, id: user._id };
      userArr.push(userObj);
    })

    if (userArr?.length > 0) {
      setUnitUserTypes(userArr);
      setUserType(userArr[0]);
    }

  };

  const getUnitUserTypes = () => {
    APIService.getAllFacilityUnitUserTypes().then((res => {
      setUnitUserTypes(res);
      setUserType(res[0]);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const getFacilityTypes = () => {
    APIService.getAllFacilityTypes().then((res => {
      if (res?.length > 0) {
        let fArr = res.filter(f => f.facilityTypeName !== "Colony");
        setFacilityTypes(fArr);
        onSelectFacilityType(fArr[0]?.id)
      }

    }), ((error) => {
      console.log("error::", error)
    }))
  };

  // useEffect(() => {
  //   bindForm(userObj, unitData)
  // }, [userObj, unitData, formValue?.unitUserId]);

  useEffect(() => {
    console.log('facilities update456');
    const s = APIService.refreshFacilities((res) => {
      dispatch(getFacilityData(res?.facilityData));
    });
    console.log("return value", s);
  }, []);

  // useEffect(() => {
  //   onSelectUnitType(formValue?.unitTypeId);
  // }, [facilityUnitTypes]);

  useEffect(() => {
    console.log('facilities update');
    setBlocks(facility?.blocks);
    onSelectBlock(facility?.blocks[0]?._id);
    setRoads(facility?.roads);
    onSelectRoad(facility?.roads[0]?._id);
    setUnitTypes(facility?.unitTypes);
    setSelectedUnitType(facility?.unitTypes[0]);

  }, [facilities]);

  useEffect(() => {
    let arr = getCountries().map((country) => ('+' + getCountryCallingCode(country))).sort();
    arr = [...new Set(arr)]
    setCountryCodes(arr);
    //getBlocksCall();

    if (property?._id) {
      getUnitDetails();
    }

    // if (facility?.facilityTypeName == 'Colony') {
    //     getFacilityTypes();
    // } else {
    //   getUnitTypes();
    // }
    //getUnitUserTypes();

    setShowLoader(false)
  }, []);


  const onSelectBlock = (id) => {
    console.log("id::", id);
    setFormValue({ ...formValue, blockId: id });
    let selectedBlock = blocks?.filter(b => b._id == id);
    if (selectedBlock?.length > 0) {
      setSelectedBlock(selectedBlock[0]);
      if (facility?.facilityTypeName == 'Colony') {
        let roadList = selectedBlock[0]?.roadList;
        setRoads(roadList);
        setSelectedRoad(roadList[0])
        //onSelectRoad(roadList[0]?.id);
      }
    }

  }

  const onSelectRoad = (id) => {
    setFormValue({ ...formValue, roadId: id });
    let selectedRoad = roads?.filter(r => r._id == id);
    if (selectedRoad?.length > 0) setSelectedRoad(selectedRoad[0])
  }

  const onSelectUnitType = (id) => {
    setFormValue({ ...formValue, unitTypeId: id });
    let selectedUnitType = facilityUnitTypes?.filter(u => u._id == id);
    if (selectedUnitType?.length > 0) setSelectedUnitType(selectedUnitType[0])
  }

  // const onSelectFacilityType = (id) => {
  //   setFormValue({ ...formValue, unitTypeId: id });
  // }

  const onSelectUnitUserType = (id) => {
    setFormValue({ ...formValue, unitUserTypeId: id });
  }

  const onSelectGender = (val) => {
    setFormValue({ ...formValue, gender: val });
  }

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const saveUnit = async (e) => {
    e.preventDefault();
    console.log(formValue.blockId, 'formValue.blockId')
    let payload = {
      "facility_id": facilityId,
     // "prefredUserId": formValue.unitUserTypeId ? formValue.unitUserTypeId : '',
      "unity_type_id": formValue.unitTypeId ? formValue.unitTypeId : selectedUnitType?._id ? selectedUnitType?._id : '',
      "block_id": formValue.blockId ? formValue.blockId : selectedBlock?._id ? selectedBlock?._id : '',
      "unity_number": formValue.name,
      "user_type": userId,
      "email": formValue.email,
      "mobile_no": formValue.mobile,
      "country_code": formValue.extension,
      "first_name": formValue.firstName,
      "last_name": formValue.lastName,
      "display_name": formValue.firstName,
      //"isPreferredUser": userId == 1 ? true : false,
     // "acceptTerms": true,
      "gender": formValue.gender,

    }
    // payload.unitTypeId = formValue.unitTypeId ? formValue.unitTypeId : '';

    if (facility?.facility_type?.facilityName == 'Colony') payload.road_id = formValue.roadId ? formValue.roadId : selectedRoad?._id ? selectedRoad?._id : '';

    console.log('payload', payload);
    if (!payload.block_id) {
      alert('Please Select Block');
      return;
    }

    if (!payload.road_id && (facility?.facilityTypeName == 'Colony')) {
      alert('Please Select Road');
      return;
    }

    if (!payload.unity_type_id) {
      alert('Please Select Unit Type');
      return;
    }

    if(!payload.country_code) {
      alert('Please Select Country');
      return;
    }

    if (!formValue.name || !formValue.mobile || !formValue.firstName ) {
      return;
    }

    if (property?._id) {
      updateUnit(payload);
    } else {
      createUnit(payload);
    }

  };

  const createUnit = (payload) => {
    APIService.saveUnits(payload).then((res => {
      setAlert({ show: true, message: "Successlly Saved..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error)
    }))
  }

  const updateUnit = (payload) => {
    payload.id = property._id;
    //payload.unitUsers[0].id = formValue.unitUserId;
    APIService.updateUnits(payload).then((res => {
      setAlert({ show: true, message: "Updated Successlly..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
      //navigate(-1);
    }), ((error) => {
      console.log("error::", error);
    })
    )
  }

  return (
    <Fragment>
      <div className="addEditPropertyForm">
        {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
        {showLoader ?
          <Loader /> :
          <>
            <MDBValidation className='row g-3 my-3' onSubmit={saveUnit} >
              <div className="spBlock p-5 pb-2">
                <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                  <div className="col-3">Block *</div>
                  <div className="form-outline col-6">
                    <select className="p-2 rounded-2" onChange={e => onSelectBlock(e.target.value)} value={formValue.blockId}>
                      {blocks &&
                        blocks.map((item) => (
                          <option key={item._id} value={item._id}
                          //selected={item.id == formValue.blockId} 

                          >{item.block_name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-3 text-center">
                    <Link to="/AddEditBlock" state={{ facilityId: facility._id, selectedBlock }} style={{ pointerEvents: blockUpdate ? '' : 'none' }} >
                      <i className="far fa-edit "></i>
                    </Link>
                    <Link to="/AddEditBlock" state={{ facilityId: facility._id, selectedBlock: {} }} style={{ pointerEvents: blockCreate ? '' : 'none' }}>
                      <i className="fas fa-plus"></i>
                    </Link>
                  </div>
                </div>
                {facility?.facility_type?.facilityName == 'Colony' &&
                  <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                    <div className="col-3">Road *</div>
                    <div className="form-outline col-6">
                      <select className="p-2 rounded-2" onChange={e => onSelectRoad(e.target.value)} value={formValue.roadId}>
                        {roads &&
                          roads.map((item) => (
                            <option key={item._id} value={item._id}
                            //selected={item.id == formValue.blockId} 
                            >{item.road_name}</option>
                          ))}
                      </select>
                    </div>
                    <div className="col-3 text-center">
                      <Link to="/AddEditRoad" state={{ facilityId: facility._id, selectedBlock, selectedRoad }} style={{ pointerEvents: blocks?.length > 0 ? '' : 'none' }}>
                        <i className="far fa-edit "></i>
                      </Link>
                      <Link to="/AddEditRoad" state={{ facilityId: facility._id, selectedBlock, selectedRoad: {} }} style={{ pointerEvents: blocks?.length > 0 ? '' : 'none' }}>
                        <i className="fas fa-plus"></i>
                      </Link>
                    </div>
                  </div>
                }
                <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                  <div className="col-3">Unit Type *</div>
                  <div className="form-outline col-6">
                    <select className="p-2 rounded-2" onChange={e => onSelectUnitType(e.target.value)} value={formValue.unitTypeId}>
                      {facilityUnitTypes &&
                        facilityUnitTypes?.map((item) => (
                          <option key={item._id} value={item._id}
                          //selected={item.id == formValue.unitTypeId}
                          >{item.unit_type}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-3 text-center">
                    <Link to="/AddEditUnitForm" state={{ facilityId: facility._id, selectedUnitType }} style={{ pointerEvents: blocks?.length > 0 && unitUpdate ? '' : 'none' }}>
                      <i className="far fa-edit "></i>
                    </Link>
                    <Link to="/AddEditUnitForm" state={{ facilityId: facility._id, selectedUnitType: {} }} style={{ pointerEvents: blocks?.length > 0 && unitCreate ? '' : 'none' }}>
                      <i className="fas fa-plus"></i>
                    </Link>
                  </div>
                </div>

                {/* {facility?.facilityTypeName == 'Colony' ?
                <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                  <div className="col-3">Type *</div>
                  <div className="form-outline col-6">
                    <select className="p-2 rounded-2" onChange={e => onSelectFacilityType(e.target.value)} value={formValue.unitTypeId}>
                      {facilityTypes &&
                        facilityTypes.map((item) => (
                          <option key={item.id} value={item.id}
                          //selected={item.id == formValue.unitTypeId}
                          >{item.facilityTypeName}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-3 text-center">
                  </div>
                </div>
                :
                <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                  <div className="col-3">Unit Type *</div>
                  <div className="form-outline col-6">
                    <select className="p-2 rounded-2" onChange={e => onSelectUnitType(e.target.value)} value={formValue.unitTypeId}>
                      {facilityUnitTypes &&
                        facilityUnitTypes.map((item) => (
                          <option key={item.id} value={item.id}
                          //selected={item.id == formValue.unitTypeId}
                          >{item.name}</option>
                        ))}
                    </select>
                  </div>
                  <div className="col-3 text-center">
                    <Link to="/AddEditUnitForm" state={{ facilityId: facility.id, selectedUnitType }} style={{ pointerEvents: blocks?.length > 0 ? '' : 'none' }}>
                      <i className="far fa-edit "></i>
                    </Link>
                    <Link to="/AddEditUnitForm" state={{ facilityId: facility.id, selectedUnitType: {} }} style={{ pointerEvents: blocks?.length > 0 ? '' : 'none' }}>
                      <i className="fas fa-plus"></i>
                    </Link>
                  </div>
                </div>} */}

                <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                  <div className="col-3">Unit Num *</div>
                  <MDBValidationItem className='col-md-6' feedback='Please enter Unit Num.' invalid>
                    <MDBInput
                      value={formValue.name}
                      name='name'
                      onChange={onChange}
                      id='name'
                      required
                      label='Unit Num *'
                    />
                  </MDBValidationItem>
                  <div className="col-3 text-center">
                  </div>
                </div>
              </div>
              <div className="p-5 py-3">
                <Title title={title ? title : "Owner Details"} ></Title>
              </div>

              <div className="p-5 pt-0 pb-0">
                <div className="d-flex">
                  <MDBValidationItem className='mb-3 col-md-3' feedback='Please enter Code.' invalid>
                    <select className="rounded-2 phoneNum"
                      onChange={(e) => setFormValue({ ...formValue, extension: e.target.value })}
                      name='extension'
                      value={formValue.extension}
                    //defaultValue={countryCode} 
                    >
                      <option value={''}>Select Country.. </option>
                      {/* {getCountries().map((country) => (
                  <option key={country} value={getCountryCallingCode(country)}>
                    +{getCountryCallingCode(country)}
                  </option>
                ))} */}
                      {countryCodes &&
                        countryCodes.map((item) => (
                          <option key={item} value={item}
                          //selected={item == formValue.extension}
                          >{item}</option>
                        ))}
                    </select>
                  </MDBValidationItem>

                  <MDBValidationItem className='mb-3 col-md-9' feedback='Please enter Mobile Number.' invalid>
                    <MDBInput
                      value={formValue.mobile}
                      name='mobile'
                      onChange={onChange}
                      id='mobile'
                      required
                      label='Mobile Number *'
                      maxLength={10}
                      type={'number'}
                    />
                  </MDBValidationItem>
                </div>

                <MDBValidationItem className='mb-4 col-md-12' feedback='Please enter First Name.' invalid>
                  <MDBInput
                    value={formValue.firstName}
                    name='firstName'
                    onChange={onChange}
                    id='firstName'
                    required
                    label='First Name *'
                  />
                </MDBValidationItem>

                <MDBValidationItem className='mb-4 col-md-12' feedback='Please enter Last Name.' invalid>
                  <MDBInput
                    value={formValue.lastName}
                    name='lastName'
                    onChange={onChange}
                    id='lastName'
                    label='Last Name'
                  />
                </MDBValidationItem>

                <MDBValidationItem className='mb-3 col-md-12' feedback='Please enter Email.' invalid>
                  <MDBInput
                    value={formValue.email}
                    name='email'
                    onChange={onChange}
                    id='email'
                    label='Email'
                    type={'email'}
                  />
                </MDBValidationItem>

                <MDBValidationItem className='mb-3 col-md-12' feedback='Please select gender' invalid>
                  <select className="rounded-2 phoneNum" onChange={e => onSelectGender(e.target.value)} value={formValue.gender} name="gender" >
                    <option value="male" selected>Male</option>
                    <option value="female">Female</option>
                    <option value="Transgender">Transgender</option>
                  </select>
                </MDBValidationItem>
                {console.log('unitData123', unitData)}
                {unitData && unitData?.user?.length > 0 &&
                  <div className="d-flex justify-content-center align-items-center justify-content-between mb-2">
                    <div className="col-md-3">Preffered User Details *</div>
                    <MDBValidationItem className='mb-3 col-md-9' feedback='Please enter Code.' invalid>
                      <select className="rounded-2 phoneNum" onChange={e => onSelectUnitUserType(e.target.value)} value={formValue.unitUserTypeId} >
                        {unitUserTypes &&
                          unitUserTypes.map((item, index) => (
                            <option key={index} value={item.id} >{item.userType}</option>
                          ))}
                      </select>
                    </MDBValidationItem>
                  </div>}


              </div>
              <div className="text-center">
                <MDBBtn type='submit'>Submit</MDBBtn>
              </div>
            </MDBValidation>

            {(!title && property?._id) &&
              <>
                <div className="d-flex jutify-content-between p-5 py-2">
                  <div className="col-9">
                    <Title title="Tenent Details" />
                  </div>
                  <div className="col-3 text-end view">
                    <Link to="/TenentForm" state={{ property: property }} >
                      {tenentExist ? 'View' : 'ADD'}
                    </Link>
                  </div>
                </div>

                <div className="d-flex jutify-content-between p-5 py-2">
                  <div className="col-9">
                    <Title title="Caretaker Details" />
                  </div>
                  <div className="col-3 text-end view" >
                    <Link to="/CareTakerFrom" state={{ property: property }}>
                      {careTakerExist ? 'View' : 'ADD'}
                    </Link>
                  </div>
                </div>
              </>}
          </>}
      </div>
    </Fragment>
  )
}

export default AddEditPropertyForm
