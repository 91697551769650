import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

// Component Imports
import Title from "../../common/Title"
import Executive from './Executive'
import APIService from '../../Services/APIService';

// CSS IMPORTS
import './Society.css';


const Executives = () => {
  const location = useLocation();
  const { society, executive } = location.state;

  const [executives, setExecutives] = useState([]);

  useEffect(() => {
    getSocietyExecutives();
  }, []);

  const getSocietyExecutives = () => {
    let payload = {
      "societyId": society?.id,
      "roletype": executive,
      "status": true
    }
    APIService.getSocietyCommitte(payload).then((res => {
      console.log("societyId::", res);
      setExecutives(res)
    }), ((error) => {
      console.log("error::", error);
      setExecutives([]);
    }))
  };


  return (
    <div className='p-3'>
      <Title title="EXECUTIVES" />
      {executives && executives.map(e =>
        <Executive key={e.id}
          name={e?.user?.firstName + ' ' + e?.user?.lastName}
          designation={e?.societyRole?.roleName}
          phone={e?.user?.mobileNumber}
          responsibility={e?.societyRole?.responsibilityType}
          selected={e} />
      )}
      {/* <Executive name="Deepika" designation="President" phone="98456 32145"/>
        <Executive name="Mahindra" designation="Vice President" phone="98745 657945"/>
        <Executive name="Indranath" designation="Tresurer" phone="98856 89324"/>
        <Executive name="Kamalakar" designation="President" phone="98796 21486"/>
        <Executive name="Naresh" designation="Tresurer" phone="98785 78534"/> */}
    </div>
  )
}

export default Executives