import React from "react"
import Tile from "./Tile"
import Title from '../common/Title'
import {MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit"

import "./Notifications.css"

const Approvals = () => {

  const data = [
    { id: 1, title: "New Charges Posted", description: "Lorem ipsum lorem", bgColor: "#ff928e", icon: "file-alt" },
    { id: 2, title: "New Post", description: "Lorem ipsum lorem", bgColor: "#939df2", icon: "envelope"},
    { id: 3, title: "31,000.00", description: "Lorem ipsum lorem", bgColor: "#49d391", icon: "signal"},
  ];

  return (
    <div className="p-2">
      <Title title="NOTIFICATIONS"/>
      <MDBContainer className="notification d-flex">
      {
        data && data.map((item) => (
          <Tile key={item.id} item={item}/>    
        ))
      }
      
      <MDBCol className="tile p-2 mx-1 d-flex align-items-center justify-content-center notificationViewMore">
      <div className="p-2">
          <a href="" className="m-0"> VIEW MORE </a>
      </div>
    </MDBCol>
    </MDBContainer>
    </div>
  )
}

export default Approvals
