import React from "react"
import { Link } from 'react-router-dom';

// Component Import's
import Title from "../../common/Title"

// Css Import's

import "./MyPropertyInfo.css"

const MyPropertyInfo = () => {

  return (
    <div className="container myPropertyInfo">
      <div className="row py-3">
        <Title title="MY PROPERTY" />
        <div className="col-4 text-center">
          <Link to="/Flats" >
            <div className="rounded-2 py-3 box flatBox">
              <i className="fas fa-building fs-1"></i>
            </div>
          </Link>
          <small className="d-block fw-bold mt-1">Units</small>
        </div>

        <div className="col-4 text-center">
          <div className="rounded-2 py-3 box comitteeBox">
            <i className="fas fa-users fs-1"></i>
          </div>
          <small className="d-block fw-bold my-1">Comittee</small>
        </div>

        <div className="col-4 text-center">
          <Link to="/Society">
            <div className="rounded-2 py-3 box societyBox">
              <i className="fas fa-city fs-1"></i>
            </div>
          </Link>
          <small className="d-block fw-bold my-1">Society</small>
        </div>

        <div className="col-4 text-center">
          <div className="rounded-2 py-3 box bankBox">
            <i className="fas fa-university fs-1"></i>
          </div>
          <small className="d-block fw-bold my-1">Bank</small>
        </div>

        <div className="col-4 text-center">
          <div className="rounded-2 py-3 box documentBox">
            <i className="fas fa-file-alt fs-1"></i>
          </div>
          <small className="d-block fw-bold my-1">Documents</small>
        </div>

        <div className="col-4 text-center">
          <div className="rounded-2 py-3 box communicationsBox">
            <i className="fas fa-wifi fs-1"></i>
          </div>
          <small className="d-block fw-bold my-1">Communications</small>
        </div>
      </div>
    </div>
  )
}

export default MyPropertyInfo
