import React, { useState } from "react"

import DrillDownMenu from "../../common/DrillDownMenu";
import useComponentVisible from "../../common/useComponentVisible";

const PropertyListItem = ({plotNumber, type, block, property}) => {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);
    const [show, setShow] = useState(false)

    const showMenu = () => {
        //console.log("show", show)
        setIsComponentVisible(!isComponentVisible)
    }

    const menuListObj = [
        {link : "Edit", path : "/AddEditPropertyForm", property},
        {link : "Delete", path : "/Flats", property},
    ]

  return (
    <div className="d-flex justify-content-center justify-content-between propertyListItem px-3 py-2 mb-2">
      <div className="px-3">
        <i className="fas fa-home"></i>
      </div>
      <div className="px-3 fw-bold"><small>{plotNumber}</small></div>
      <div className="px-3 fw-bold"><small>{type}</small></div>
      <div className="px-3 fw-bold"><small>{block}</small></div>
      <div className="px-3 position-relative">
        <i className="fas fa-ellipsis-v" ref={ref} style={{cursor: "pointer"}} onClick={showMenu}></i>
        { isComponentVisible ? <DrillDownMenu obj={menuListObj}/> : null }
      </div>
    </div>
  )
}

export default PropertyListItem
