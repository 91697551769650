import React from "react"
import {MDBBtn, MDBIcon, MDBRow, MDBCol} from "mdb-react-ui-kit"

const Tile = (  {item, type} ) => {
  return (
    <MDBCol className={`tile p-2 mx-1`}>
      <div className="d-flex align-items-center justify-content-center">
        <div className="icon">
          <MDBIcon far fas icon={ item.icon } />
        </div>
        <div className="ms-2 visitorInfo">
          <small>Charge Collected</small>
          <h6 className="m-0"> { item.title } </h6>
          <small> { item.description } </small>
        </div>
      </div>
    </MDBCol>
  )
}

export default Tile
