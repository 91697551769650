import React from "react"
import Tile from "./Tile"
import Title from "../common/Title"
import {MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit"
// import PaintingImage from '../../../public/images/Advertosements/carpenter.jpg'

import "./Advertisements.css"

const Advertisements = () => {
  const data = [
    {
      id: 1,
      image: "/images/Advertosements/carpenter.jpg",
      price: "50% OFF",
      description: "Carpentry",
      bgColor: "#ff928e",
    },
    {
      id: 2,
      image: "/images/Advertosements/ac-repair.jpg",
      price: "25% OFF",
      description: "AC Repairs",
      bgColor: "#939df2",
    },
    {
      id: 3,
      image: "/images/Advertosements/painting.jpg",
      price: "30% OFF",
      description: "Painig",
      bgColor: "#49d391",
    },
  ]

  return (
    <div className="p-2">
      <Title title="ADVERTOSEMENTS" />
      <MDBContainer className="advertisements">
        {data && data.map((item) => <Tile key={item.id} item={item} />)}
      </MDBContainer>
    </div>
  )
}

export default Advertisements;
