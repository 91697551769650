import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from "react-router-dom";

// Component Imports
import Title from "../../common/Title";
import { useSelector } from "react-redux";
import APIService from '../../Services/APIService';
import Alert from '../../common/Alert';

const EditExecutive = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const blocks = useSelector((state) => state?.appSlice?.blocks);
  const facility = useSelector((state) => state?.appSlice?.facility);

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });

  const { executive } = location.state;

  const [selectedBlockId, setSelectedBlock] = useState(executive?.unit?.blockId ? executive?.unit?.blockId : blocks?.length > 0 ? blocks[0]?.id : '');

  const [facilityUnits, setFacilityUnits] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(executive?.unitId ? executive?.unitId : 0);

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(executive?.societyRoleId ? executive?.societyRoleId : 0);

  const [unitUsers, setUnitUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(executive?.userId ? executive?.userId : 0);

  const selectBlock = (id) => {
    setSelectedBlock(id);
  }

  const selectUnit = (id) => {
    setSelectedUnit(id);
    setUnitUsers([]);
  }

  const selectRole = (id) => {
    setSelectedRole(id);
  }

  const selectUser = (id) => {
    setSelectedUser(id);
  }

  const getUnitDetails = (unitId) => {

    APIService.getFacilityUnit(unitId).then((res => {
      console.log('res', res);
      if (res?.unitUsers) {
        setUnitUsers(res?.unitUsers);
        setSelectedUser(res?.unitUsers[0]?.userId);
      }
    }), ((error) => {
      console.log("error::", error);
      setUnitUsers([]);
    }))
  };

  useEffect(() => {
    getFacilityUnits();
  }, [selectedBlockId]);

  useEffect(() => {
    if (selectedUnit) getUnitDetails(selectedUnit);
  }, [selectedUnit]);

  useEffect(() => {
    getSocietyRoles();
  }, []);

  const getSocietyRoles = () => {
    let payload = {
      "societyId": executive?.societyId,
      "status": true
    }
    APIService.getSocietyRoles(payload).then((res => {
      console.log("societyId::", res);
      setRoles(res);
      if (!executive?.societyRoleId) setSelectedRole(res[0]?.id);
    }), ((error) => {
      console.log("error::", error);

    }))
  };

  const getFacilityUnits = () => {
    let payload = {
      "facility_id": facility.id,
      "block_id": selectedBlockId,
      "status": true
    }

    APIService.getUnits(payload).then((res => {
      console.log(res)
      setFacilityUnits(res);
      if (!executive?.unitId) setSelectedUnit(res[0]?.id);
    }), ((error) => {
      console.log("error::", error);
      setFacilityUnits([]);
      setUnitUsers([]);
    }))
  };

  const saveExecutive = () => {
    console.log('submit')
    if (!selectedBlockId || !selectedUnit || !selectedRole || !selectedUser) {
      alert('Please Select required values');
      return;
    }
    let payload = {
      "id": executive?.id,
      "unitId": selectedUnit,
      "userId": selectedUser,
      "societyId": executive?.societyId,
      "societyRoleId": selectedRole,
      "roleType": executive?.roleType
    };
    console.log("payload::", payload);
    APIService.updateSocietyCommitte(payload).then((res => {
      console.log("society::", res);
      setAlert({ show: true, message: "Updated Successlly..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error);

    }))

  }

  return (
    <div className=''>
      {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
      <div className='px-3 pt-4'><Title title="EDIT EXECUTIVES" /></div>

      <div className='px-3  mt-4'><Title title="Select Block" /></div>

      <div className='horizontalScroll selectBlock d-flex align-items-center'>
        {blocks &&
          blocks.map((item) => (
            <div key={item.id} className={selectedBlockId == item.id ? 'btn btn-primary p-3 me-4' : 'btn inActiveBtn p-3 me-4'} onClick={() => selectBlock(item.id)} ><small> {item.blockName}</small></div>
          ))}
        {/* <Link to="" className='btn btn-primary p-3 me-4' >Main Block</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Phase 1</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Phase 2</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Phase 3</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Phase 4</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Phase 5</Link> */}
      </div>

      <div className='px-3  mt-4'><Title title="Select Unit" /></div>

      <div className='horizontalScroll selectBlock d-flex align-items-center'>
        {facilityUnits &&
          facilityUnits.map((item) => (
            <div key={item.id} className={selectedUnit == item.id ? 'btn btn-primary rounded-circle p-3 me-4' : 'btn inActiveBtn rounded-circle p-3 me-4'} onClick={() => selectUnit(item.id)} ><small> {item.name}</small></div>
          ))}
        {/* <Link to="" className='btn btn-primary rounded-circle p-3 me-4' >101</Link>
        <Link to="" className='btn inActiveBtn rounded-circle p-3 me-4' >102</Link>
        <Link to="" className='btn inActiveBtn rounded-circle p-3 me-4' >103</Link>
        <Link to="" className='btn inActiveBtn rounded-circle p-3 me-4' >104</Link>
        <Link to="" className='btn inActiveBtn rounded-circle p-3 me-4' >105</Link>
        <Link to="" className='btn inActiveBtn rounded-circle p-3 me-4' >106</Link> */}
      </div>

      <div className='px-3 mt-4'><Title title="Select Person" /></div>
      <div className='horizontalScroll selectBlock d-flex align-items-center'>
        {unitUsers &&
          unitUsers.map((item) => (
            <div key={item.unitUserId} className={selectedUser == item.userId ? 'btn btn-primary p-3 me-4' : 'btn inActiveBtn p-3 me-4'} onClick={() => selectUser(item.userId)} ><small> {item.firstName}{' ( '}{item.userType}{' ) '}</small></div>
          ))}
        {/* <Link to="" className='btn btn-primary p-3 me-4' >Deepika ( Owner )</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Usha ( Tenent )</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Kamalakar ( Owner )</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Vijay ( Owner )</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Kishore ( Owner )</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Ravi Kumar ( Tenent )</Link> */}
      </div>

      <div className='px-3 mt-4'><Title title="Roles" /></div>
      <div className='horizontalScroll selectBlock d-flex align-items-center'>
        {roles &&
          roles.map((item) => (
            <div key={item.id} className={selectedRole == item.id ? 'btn btn-primary p-3 me-4' : 'btn inActiveBtn p-3 me-4'} onClick={() => selectRole(item.id)} ><small> {item.roleName}</small></div>
          ))}
        {/* <Link to="" className='btn btn-primary p-3 me-4' >President</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Vice President</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Vice President</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Vice President</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Vice President</Link>
        <Link to="" className='btn inActiveBtn p-3 me-4' >Vice President</Link> */}
      </div>

      <div className='text-center mt-5'>
        <button
          type="submit"
          className="btn btn-primary px-4 p-3 fs-6"
          onClick={saveExecutive}
        >SUBMIT </button>
      </div>

    </div>
  )
}

export default EditExecutive