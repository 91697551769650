import React from "react"

// Components Import's
import Carousel from "../../Carousel"
import NewVisitor from "../../NewVisitor"
import Approvals from "../../Approvals/Approvals"
import Notifications from "../../Notifications/Notifications"
import ClientDues from "../../ClientDues/ClientDues"
import Communications from "../../Communications/Communications"
import UtilityServices from "../../UtilityServices/UtilityServices"
import Advertisements from "../../Advertisements/Advertisements"
import Rentsale from "../../Rentsale/Rentsale"

const Main = () => {
  return (
    <>
      <Carousel />
      <NewVisitor />
      <Approvals />
      <Notifications />
      <ClientDues />
      <Communications />
      <UtilityServices />
      <Rentsale />
      {/* <Rentsale /> */}
      <Advertisements />
    </>
  )
}

export default Main
