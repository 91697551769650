import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import OTPInput from "react-otp-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useSelector, useDispatch } from "react-redux";
import "./LoginPage.css";
import "./Otp.css";
import { updateToken } from "../../redux-store/appSlice";
import APIService from "../Services/APIService";
import userProfile from "../common/userProfile";
import { useUserAuth } from "../../context/UserAuthContext";
import { updateUserData } from "../../redux-store/userSlice";
import { getFacilityData } from "../../redux-store/facilitySlice";

const Login = ({ navbarHide, logged }) => {
  const dispatch = useDispatch();
  const sidebarShow = useSelector((state) => state);
  const [mynumber, setnumber] = useState("");
  const { setUpRecaptha } = useUserAuth();
  const [OTP, setOTP] = useState("");
  const [show, setShow] = useState(true);
  const [result, setResult] = useState("");
  const navigate = useNavigate();

  const routeChange = async (e) => {
    e.preventDefault();
    if (mynumber === "" || mynumber === undefined)
      return alert("Please enter a valid phone number!");
    try {
      const response = await setUpRecaptha(mynumber);
      setResult(response);
      //alert("code sent");
      setShow(false);
      dispatch(updateToken(response?.verificationId));
    } catch (err) {
      alert(err.message);
      window.location.reload();
    }
  };

  const sendOtp = async (e) => {
    e.preventDefault();
    if (OTP === "" || OTP === null || OTP?.length < 6)
      alert("Please enter OTP!");
    try {
      await result.confirm(OTP);
      APIService.getUserDetails().then((res => {
        if (res?.usr_msg == "success") {
          userProfile.setUserObj(res.user);
          dispatch(updateUserData(res.user));
          if (res?.user && res.user?.user_facilities?.length > 0) {
            const facilityIds = res.user?.user_facilities?.map((f) => f.facility_id);
            console.log('facilityIds', facilityIds)
            let payload = {
              facility: facilityIds
            }
            getFacilities(payload);
          }
        }
      }), ((error) => {
        console.log("error::", error);
        setShow(true);
        alert('User not Found!..');
      }))
    } catch (err) {
      alert(err.message);
    }
  };

  const getFacilities = (payload) => {
    APIService.getFacilities(payload).then((res => {
      console.log(res);
      if(res?.facilityData?.length > 0 ){
        userProfile.setFacilities(res.facilityData);
        dispatch(getFacilityData(res?.facilityData));
        setTimeout(() => {
          let path = `/MainPage`;
          navigate(path);
          navbarHide();
        },
          1500);
      }
      
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  return (
    <div>
      {show === true ? (
        <div
          className="container d-flex flex-column justify-content-center align-items-center px-5 loginPage"
          style={{ height: "88vh" }}
        >
          <div className="row">
            <div className="text-center mb-5">
              <img src="/images/logo.png" alt="" />
            </div>
            <form className="my-5 inline" onSubmit={routeChange}>
              <div className="form-outline mb-4 input-icons">
                <i className="fas fa-mobile-alt fs-3 trailing icon"></i>
                <PhoneInput
                  defaultCountry="IN"
                  value={mynumber}
                  onChange={setnumber}
                  inputStyle={{ height: "40px", padding: "20px" }}
                  placeholder="Please Enter Your Phone Number"
                  id="form12"
                  className="input-field p-1"
                />
                <div className="mt-2 mb-2" id="recaptcha-container"></div>
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block p-3 rounded-pill fs-6"
              >
                Request OTP
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div
          className="container d-flex flex-column justify-content-center align-items-center px-5 otp"
          style={{ height: "88vh" }}
        >
          <h2 className="title fw-bold fs-4">OTP</h2>
          <img src="/images/otp-verify.png" alt="" className="my-2" />
          <div className="text-center">
            <p className="fs-5 fw-bold">Verification Code</p>
            <small>
              We have sent the code verification to Your Mobile Number
            </small>
            <p className="fs-5 fw-bold m-0 phoneNumber">{mynumber}</p>
            <div className="container mb-2">
              <OTPInput
                value={OTP}
                onChange={(OTP) => setOTP(OTP)}
                autoFocus
                numInputs={6}
                isInputNum={true}
                disabled={false}
                secure
                inputStyle={{
                  width: "60px",
                  margin: "2px",
                }}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block p-3 rounded-pill fs-6"
              onClick={(e) => sendOtp(e)}
            >
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
