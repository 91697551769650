import React from "react"
import Tile from "./Tile"
import Title from '../common/Title'
import {MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit"

import "./Communications.css"

const Communications = () => {

  const data = [
    { id: 1, title: "Notice Board", name: "Budaiaha", description: "Lorem ipsum lorem", icon: "file-alt" },
    { id: 2, title: "Meeting", name: "Budaiaha", description: "Lorem ipsum lorem", icon: "file-alt" },
    { id: 3, title: "Notice Board", name: "Budaiaha", description: "Lorem ipsum lorem", icon: "file-alt" },
    { id: 4, title: "Meeting", name: "Budaiaha", description: "Lorem ipsum lorem", icon: "file-alt" },
  ];

  return (
    <div className="p-2">
      <Title title="COMMUNICATIONS"/>
      <MDBContainer className="communications d-flex">
      {
        data && data.map((item) => (
          <Tile key={item.id} item={item}/>    
        ))
      }
      
      <MDBCol className="tile p-2 mx-1 d-flex align-items-center justify-content-center communicationsViewMore">
      <div className="p-2">
          <a href="" className="m-0"> VIEW MORE </a>
      </div>
    </MDBCol>
    </MDBContainer>
    </div>
  )
}

export default Communications
