import React from "react"
import {Link} from "react-router-dom"
import {MDBRow, MDBCol, MDBBtn, MDBIcon} from "mdb-react-ui-kit"

const Executive = ({name, designation, phone, responsibility, selected}) => {
  return (
    <MDBRow className="executive mb-4">
      <MDBCol className="col-3 text-center">
        <MDBBtn floating tag="a" className="avatar">
          <MDBIcon fas icon="user-tie" />
        </MDBBtn>
      </MDBCol>
      <MDBCol className="col-9 p-2 border-bottom border-primary">
        <div className="d-flex align-tems-center justify-content-between">
          <div>
            <h5 className="name mb-0">{name}</h5>
            <small>{designation}</small>
          </div>
          <div>
            <Link to="/EditExecutive" className="float-end" state={{executive: selected }}>
              <MDBIcon far icon="edit" />
            </Link>
          </div>
        </div>

        <div className="d-flex align-tems-center justify-content-between my-2 ">
          <div className="fs-4">
            <MDBIcon fas icon="mobile-alt" className="me-2" />
            <span>{phone}</span>
          </div>
          <div>
            <MDBIcon fas icon="phone" />
          </div>
        </div>

        <div>
          {responsibility}
          {/* Cheque Power, Upload Bill, Approve Bill, Visitors, Payment, Collect
          Charges, Initiate Charges, Change Approval */}
        </div>
      </MDBCol>
    </MDBRow>
  )
}

export default Executive
