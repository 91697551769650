import React from "react"
import {MDBRow, MDBCol} from "mdb-react-ui-kit"

const Tile = ({item}) => {
  return (
    <MDBCol className={`tile p-2 mx-1`}>
      <div className="">
        <div className="ms-2">
          <div>
            <img src={item?.image_url ? item?.image_url : '/images/RentSale/house2.jpg'} className="img-responsive" />
          </div>
          <h6 className="m-0"> {item.name} </h6>
          {/* <small> {item.description} </small> */}
        </div>
      </div>
    </MDBCol>
  )
}

export default Tile
