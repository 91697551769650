import React, { useState } from "react"
import { logOut } from "../../context/UserAuthContext";
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBBtn,
  MDBNavbarNav,
  MDBIcon,
  MDBPopconfirm,
  MDBPopconfirmMessage
} from "mdb-react-ui-kit"
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Navbar() {
  const [showNavNoTogglerThird, setShowNavNoTogglerThird] = useState(false)
  const user = useSelector((state) => state?.userSlice?.userData);
  const navigate = useNavigate();
  //const { logOut } = logOut();

  const logOutApp = () => {
    //console.log('logout called')
    window.localStorage.clear();
    window.location.reload(false);
    // //  logOut()
    //   let path = `/`
    //   navigate(path)
    //TODO
    // clear local store, redux, firebase
  };

  return (
    <>
      <div
        className="d-flex flex-nowrap fixed-top"
        style={{ backgroundColor: "#5f017d" }}
      >
        <div className="justify-content-center align-items-center d-flex">
          <MDBNavbarToggler
            type="button"
            data-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoTogglerThird(!showNavNoTogglerThird)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          <Link to="" className="backLink" onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-circle-left"></i>
          </Link>
        </div>

        <div className="p-2 flex-grow-1 text-white fs-6 justify-content-center align-items-center d-flex">
          Hi {user?.first_name}
        </div>

        <div className="p-2 d-flex">
          <MDBNavbarLink href="#">
            <MDBIcon fas icon="cog" className="fs-5 text-white" />
          </MDBNavbarLink>
          <MDBNavbarLink href="#">
            <MDBIcon fas icon="bell" className="fs-5 text-white" />
          </MDBNavbarLink>
          <MDBPopconfirm placement='bottom' btnChildren={ <MDBIcon fas icon="sign-out-alt" className="fs-5 text-white" />} onConfirm={()=> logOutApp()}>
            <MDBPopconfirmMessage>Are you Sure, You want to Logout?</MDBPopconfirmMessage>
          </MDBPopconfirm>
        </div>
      </div>

      <MDBCollapse
        navbar
        show={showNavNoTogglerThird}
        style={{ backgroundColor: "#5f017d", zIndex: "99" }}
      >
        <MDBNavbarNav
          className="mr-auto mb-2 mb-lg-0 p-3"
          style={{ margin: "40px 0 0" }}
        >
          <MDBNavbarItem>
            <MDBNavbarLink
              active
              aria-current="page"
              href="#"
              className="text-white"
            >
              Home
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink href="#" className="text-white">
              Link
            </MDBNavbarLink>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <MDBNavbarLink disabled href="#" tabIndex={-1} aria-disabled="true">
              Disabled
            </MDBNavbarLink>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBCollapse>
    </>
  )
}
