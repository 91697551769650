import React from "react"
import Title from "../common/Title"
import {MDBBtn, MDBIcon} from "mdb-react-ui-kit"

import "./UtilityServices.css"

const UtilityServices = () => {
  return (
    <div className="p-2">
      <Title title="UTILITY SERVICES" />
      <div className="d-flex align-items-center justify-content-center utilityServices">
        <div className="flex-grow-1 text-center">
          <MDBBtn floating tag="a">
            <MDBIcon fas icon="plug" />
          </MDBBtn>
          <p className="text-center pt-2"><small>Electrical</small></p>
        </div>
        <div className="flex-grow-1 text-center">
          <MDBBtn floating tag="a">
            <MDBIcon fas icon="hammer" />
          </MDBBtn>
          <p className="text-center pt-2"><small>Carpentary</small></p>
        </div>
        <div className="flex-grow-1 text-center">
          <MDBBtn floating tag="a">
            <MDBIcon fas icon="tools" />
          </MDBBtn>
          <p className="text-center pt-2"><small>Plumbing</small></p>
        </div>
        <div className="flex-grow-1 text-center">
          <MDBBtn floating tag="a">
            <MDBIcon fas icon="toolbox" />
          </MDBBtn>
          <p className="text-center pt-2"><small>Air Condition</small></p>
        </div>
      </div>
    </div>
  )
}

export default UtilityServices
