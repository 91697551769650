import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBIcon,
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit"

import { useLocation } from "react-router-dom";
import APIService from '../../Services/APIService';

import Title from "../../common/Title"
import moment from 'moment';

// CSS IMPORTS
import "./Society.css"

const SocietyDetails = () => {
  const location = useLocation();

  const { society } = location.state;
  const [banks, setSanks] = useState([]);

  const searchBanks = () => {
    APIService.getSocietyBanks(society?.id).then((res => {
      setSanks(res);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  useEffect(() => {
    searchBanks();
  }, []);

  return (
    <>
      <div className="p-3 society">
        <Title title="SOCIETY DETAILS" />
        <MDBListGroup flush style={{ minWidth: "22rem" }}>
          <MDBListGroupItem>
            <MDBIcon fas icon="map-marked-alt" className="me-2" /> {society?.societyName}
            {/* <Link to="/AddEditSociety" state={{ society: society }}><MDBIcon far icon="edit" className="float-end" /></Link> */}
          </MDBListGroupItem>
          <MDBListGroupItem>
            <MDBIcon far icon="calendar-alt" className="me-2" /> {society?.regNo}
          </MDBListGroupItem>
          <MDBListGroupItem>
            <MDBIcon fas icon="globe" className="me-2" />  {moment(society?.regDate).format("yyyy-MM-DD")} { }
          </MDBListGroupItem>
          <MDBListGroupItem>
            <MDBIcon far icon="calendar-alt" className="me-2" /> {moment(society?.validTill).format("yyyy-MM-DD")}
          </MDBListGroupItem>
        </MDBListGroup>
      </div>

      <div className="bankDetails society p-3 ">
        <Title title="BANK DETAILS" />

        {banks && banks.map((bank, index) => (
          <div className="p-2" key={bank.id}>
            <MDBListGroup flush className="mb-3" style={{ minWidth: "22rem" }}>
              <MDBListGroupItem>
                <MDBIcon fas icon="piggy-bank" className="me-2" />{bank.bankName}
                <Link to="/BankDetails" state={{ societyId: society?.id, bank: bank }}>
                  <MDBIcon far icon="edit" className="float-end" />
                </Link>
              </MDBListGroupItem>
              <MDBListGroupItem>
                <small>Acc : {bank.bankAccountNumber}</small>
              </MDBListGroupItem>
              <MDBListGroupItem className="p-3">
                <span className="balance p-2 px-4 fw-bold">
                  {bank.balance}
                </span>
              </MDBListGroupItem>
              <MDBListGroupItem>
                <MDBIcon fas icon="map-marker-alt" className="me-2" /> {bank.branchLocation}
                {/* Guntur, AP */}
              </MDBListGroupItem>
              <MDBListGroupItem>
                <small>IFSC : {bank.ifscCode}</small>
                <small className="float-end">Type : {bank.accountType}</small>
              </MDBListGroupItem>
            </MDBListGroup>
          </div>
        ))}

        {banks?.length == 0 && <p className="text-center">No Society banks to show</p>}

        <div className="text-center my-4">
          <Link to="/BankDetails" state={{ societyId: society?.id, bank: {} }}>
            <MDBBtn className="p-2">ADD BANK</MDBBtn>
          </Link>
        </div>

        <div className="comitte mb-3">
          <Title title="COMITTEE" />
          <MDBContainer>
            <MDBRow>
              <MDBCol size="6" className="text-center P-3 fw-bold">
                <Link to="/Executives" state={{ society: society, executive: 'Executive' }}>
                  <MDBIcon fas icon="user-tie" className="fs-4" /> <br />
                  EXECUTIVE
                </Link>
              </MDBCol>
              <MDBCol size="6" className="text-center P-3 fw-bold">
                <Link to="/Executives" state={{ society: society, executive: 'NonExecutive' }}>
                  <MDBIcon fas icon="user" className="fs-4" /> <br /> NON
                  EXECUTIVE
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>

        <div className="mb-3 society">
          <Title title="DOCUMENTS" />

          <MDBListGroup flush style={{ minWidth: "22rem" }}>
            <MDBListGroupItem>
              <MDBIcon far icon="file-alt" className="me-2" /> File
              <Link to="/Documents">
                <MDBIcon far icon="edit" className="float-end" />
              </Link>
            </MDBListGroupItem>
            <MDBListGroupItem>
              <MDBIcon far icon="copy" className="me-2" /> 256478
              <Link to="/Documents">
                <MDBIcon fas icon="file-download" className="float-end" />
              </Link>
            </MDBListGroupItem>
          </MDBListGroup>
        </div>
      </div>
    </>
  )
}

export default SocietyDetails
