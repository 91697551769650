import React from "react"
import {MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon} from "mdb-react-ui-kit"

const NewVisitor = () => {
  return (
    <MDBContainer className="py-2 newVisitor">
      <MDBRow>
        <MDBCol>
          <div className="d-flex align-items-center justify-content-center">
            <div>
              <MDBBtn floating tag="a">
                <MDBIcon fas icon="user" />
              </MDBBtn>
            </div>
            <div className="ms-2 visitorInfo">
                <small>Today</small>
                <h6 className="m-0">New Visitor</h6>
                <small>lorem ipsum lorem</small>
            </div>
          </div>
        </MDBCol>
        <MDBCol className="d-flex align-items-center justfy-content-center">
          <MDBBtn className="mx-2">Accept</MDBBtn>
          <MDBBtn>Reject</MDBBtn>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default NewVisitor
