import { createSlice } from "@reduxjs/toolkit";
import userProfile from "../Components/common/userProfile";

const initial_state = {
  facilityData: userProfile.getFacilities() || []
};
const facilitySlice = createSlice({
  name: "app/data",
  initialState: initial_state,
  reducers: {
    getFacilityData: (state, action) => {
      return { ...state, facilityData: action.payload };
    },
    
  },
});
export const { getFacilityData } = facilitySlice.actions;
export default facilitySlice.reducer;
