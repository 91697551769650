import React from "react"
import { Link } from 'react-router-dom'
import { MDBListGroup, MDBListGroupItem, MDBIcon, } from 'mdb-react-ui-kit';
import moment from 'moment';

// Component Imports
import Title from "../../common/Title"

// CSS IMPORTS
import './Society.css'

const Society = ({ index, society }) => {
  return (
    <div className="p-3 society">
      <Title title={"SOCIETY " + (index + 1)} />
      <MDBListGroup flush style={{ minWidth: "22rem" }}>
        <MDBListGroupItem><MDBIcon fas icon="home" className="me-2" />
          <Link to="/SocietyDetails" state={{ society: society }}>{society.societyName} </Link>
          <Link to="/AddEditSociety" state={{ society: society }}><MDBIcon far icon="edit" className="float-end" /></Link>
        </MDBListGroupItem>
        <MDBListGroupItem><MDBIcon fas icon="map-marked-alt" className="me-2" /> {society.regNo}</MDBListGroupItem>
        <MDBListGroupItem><MDBIcon far icon="calendar-alt" className="me-2" /> {society.regAuthority}</MDBListGroupItem>
        <MDBListGroupItem><MDBIcon fas icon="globe" className="me-2" /> {moment(society.regDate).format("yyyy-MM-DD")}</MDBListGroupItem>
        <MDBListGroupItem><MDBIcon far icon="calendar-alt" className="me-2" /> {moment(society.validTill).format("yyyy-MM-DD")}</MDBListGroupItem>
      </MDBListGroup>
    </div>
  )
}

export default Society
