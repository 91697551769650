import React from "react"
import {MDBBtn, MDBIcon, MDBRow, MDBCol} from "mdb-react-ui-kit"

const Tile = ({item, type}) => {
  return (
    <MDBCol className={`tile p-2 mx-1`} style={{background: `${item.bgColor}`}}>
      <div className="d-flex align-items-center justify-content-center">
        <div>
          <MDBBtn floating tag="a">
            <MDBIcon fas icon="user-tie" />
          </MDBBtn>
        </div>
        <div className="ms-2 visitorInfo">
          <small>Charge Collected</small>
          <h6 className="m-0"> {item.price} </h6>
          <small> {item.description} </small>
          <div className="d-flex">
            <MDBCol className="me-2">
              <a href="">
                <MDBIcon fas icon="file-invoice" /> Reject
              </a>
            </MDBCol>
            <MDBCol>
              <a href="">
                <MDBIcon fas icon="file-invoice" /> Approve
              </a>
            </MDBCol>
          </div>
        </div>
      </div>
    </MDBCol>
  )
}

export default Tile
