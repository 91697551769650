import React from "react"
import {MDBBtn, MDBIcon, MDBRow, MDBCol} from "mdb-react-ui-kit"

const Tile = (  {item, type} ) => {
  return (
    <MDBCol className={`tile p-2 mx-1`}>
      <div className="d-flex">
        <div className="icon">
          <MDBIcon far fas icon="circle" />
        </div>
        <div className="ms-2 visitorInfo">
          <small className="title">{item.title}</small>
          <h6 className="m-0"> { item.name } </h6>
          <p className="pt-2"> { item.description } </p>
          <div className="d-flex actions">
            <MDBCol className="me-2">
              <a href="">
                <MDBIcon fas icon="heart" className="me-1" /> Like
              </a>
            </MDBCol>
            <MDBCol>
              <a href="">
                <MDBIcon fas icon="comment" className="me-1" /> Reply
              </a>
            </MDBCol>
          </div>
        </div>
      </div>
    </MDBCol>
  )
}

export default Tile
