import React from "react"
import {MDBInput, MDBIcon } from "mdb-react-ui-kit"

// CSS IMPORTS
import "./Society.css"

const AddEditDocument = () => {
  return (
    <div className="p-3 society">
      <div className="form-outline mb-4">
        {/* <input type="text" id="form12" className="form-control" />
        <label className="form-label" for="form12">
          Docuemnt Name
        </label> */}
        <MDBInput label="Docuemnt Name" id="forDocuemntName" type="text" />
      </div>

      <div className="form-outline mb-4">
        <MDBInput label="Description" id="forDescription" type="text" />
      </div>
      <p className="mb-4">Access</p>
      
      <div className="form-outline mb-4 d-flex align-items-center">
        <div>
          <MDBInput label="Owner" id="forOwner" type="text" />
        </div>

        <div className="mx-2">
          <MDBInput label="Comitte Member" id="formComitteMember" type="text" />
        </div>

        <div>
          <button className="btn btn-primary">All</button>
        </div>
      </div>

      <div className="form-outline mb-3 d-flex align-items-center">
        <div className="col-4">
          Upload
        </div>

        <div className="col-8 mx-2 d-flex align-items-center justify-content-around">
        <MDBIcon fas icon="camera" />
        <MDBIcon far icon="image" />
        <MDBIcon far icon="file-alt" />
        </div>
      </div>

      <div className="text-center">
       <small>Upload File Types</small>
      </div>

      <div className="text-center mt-4">
        <button className="btn btn-primary">SUBMIT</button>
      </div>
    </div>
  )
}

export default AddEditDocument
