import { createSlice } from "@reduxjs/toolkit";
import userProfile from "../Components/common/userProfile";

const initial_state = {
  token: "",
  facilityId: userProfile.getFacilityId() || '',
  facility: {},
  facilityType: '',
  blocks: []
};
const appSlice = createSlice({
  name: "app/data",
  initialState: initial_state,
  reducers: {
    updateToken: (state, action) => {
      return { ...state, token: action.payload };
    },

    updateFacility: (state, action) => {
      return { ...state, facility: action.payload };
    },

    updateFacilityId: (state, action) => {
      return { ...state, facilityId: action.payload };
    },

    updateBlocks: (state, action) => {
      return { ...state, blocks: action.payload };
    },
  },
});
export const { updateToken, updateFacility, updateBlocks, updateFacilityId } = appSlice.actions;
export default appSlice.reducer;
