import React from "react"
import { Link } from 'react-router-dom'
import APIService from "../Services/APIService";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getPermission } from "../Services/PermissionUtil";
import Permission from "../Services/Permission";

// CSS Import's
import "./DrillDownMenu.css"

const DrillDownMenu = ({ obj }) => {
  const navigate = useNavigate();
  const facilityId = useSelector((state) => state?.appSlice?.facilityId);

  const actionUnit = (unit) => {
    if (unit.link == 'Edit') {
      navigate(unit.path, { state: { property: unit.property } })
    } else {
      const unitDelete = getPermission(facilityId, Permission.unit_delete);
      if (unitDelete) {
        let payload = {
          "id": unit?.property?._id,
          "status": false
        }
        APIService.deleteFacilityUnits(payload).then((res => {
          console.log("res", res);
          window.location.reload(false);
        }), ((error) => {
          console.log("error::", error);
        }))
      } else {
        alert('Do not have Permission to Delete Unit..')
      }

    }

  }
  return (
    <div className="position-absolute drillDownMenu px-4">
      <ul className="list-group list-group-light">
        {
          obj.map((item, index) =>
            <p key={index} style={{ cursor: 'pointer' }} onClick={() => actionUnit(item)}> {item.link}</p>
          )
        }
      </ul>
    </div>
  )
}

export default DrillDownMenu
