import React, { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom";
import APIService from "../../Services/APIService";

import {
  MDBBtn,
  MDBRadio,
  MDBInput,
  MDBValidation,
  MDBDatepicker,
  MDBValidationItem,
} from 'mdb-react-ui-kit';

// Component Imports
import Title from "../../common/Title";
import Alert from '../../common/Alert';

const BankDetails = () => {

  const location = useLocation();
  const navigate = useNavigate()

  const [accountTypes, setAccountTypes] = useState([]);
  const [states, setState] = useState([]);
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });

  const { societyId, bank } = location.state;

  const [formValue, setFormValue] =
    useState({
      bankName: bank?.bankName ? bank?.bankName : '',
      bankAccountNumber: bank?.bankAccountNumber ? bank?.bankAccountNumber : '',
      accountName: bank?.accountName ? bank?.accountName : '',
      branchName: bank?.branchName ? bank?.branchName : '',
      branchLocation: bank?.branchLocation ? bank?.branchLocation : '',
      state: bank?.state ? bank?.state : "Telangana",
      ifscCode: bank?.ifscCode ? bank?.ifscCode : '',
      accountType: bank?.accountType ? bank?.accountType : '',
      balance: bank?.balance ? bank?.balance : '',
    });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const onSelectState = (value) => {
    setFormValue({ ...formValue, state: value });
  }

  const saveSocietyBank = async (e) => {
    e.preventDefault();

    let payload = {
      "facility_id": "",
      "society_id": societyId,
      "ac_type": formValue.accountType,
      "name": formValue.bankName,
      "account_number": formValue.bankAccountNumber,
      "ac_holder_name": formValue.accountName,
      "branch": formValue.branchName,
    //  "branchLocation": formValue.branchLocation,
     // "state": formValue.state,
      "ifsc_code": formValue.ifscCode,
      "amount": formValue.balance
    }

    if (!formValue.bankName || !formValue.bankAccountNumber || !formValue.accountName || !formValue.accountType || !formValue.branchName || !formValue.branchLocation || !formValue.state || !formValue.ifscCode || !formValue.balance) return;

    if (bank?.id) {
      payload.bankId = bank?.id,
        updateBank(payload);
    } else {
      createBank(payload);
    }
  };

  const updateBank = async (payload) => {
    APIService.updateSocietyBank(payload).then((res => {
      console.log("res::", res)
      setAlert({ show: true, message: "Updated Successlly..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const createBank = async (payload) => {
    APIService.saveSocietyBank(payload).then((res => {
      console.log("res::", res)
      setAlert({ show: true, message: "Successlly Saved..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const getAccountTypes = () => {
    APIService.getAllAccountTypes().then((res => {
      setAccountTypes(res);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const getStates = () => {
    APIService.getAllStates().then((res => {
      setState(res);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  useEffect(() => {
    getStates()
    getAccountTypes()
  }, []);

  return (
    <div className="p-3 bankDetails">
      {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
      <MDBValidation className='row g-3' onSubmit={saveSocietyBank}>
        <div className="py-3">
          <Title title="ADD EDIT BANK DETAILS" />
        </div>
        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter bank name' invalid>
            <MDBInput type="text" label="Bank Name *" id="bankName" name="bankName" value={formValue.bankName} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter account number' invalid>
            <MDBInput type="text" label="Bank Account Number *" id="bankAccountNumber" name="bankAccountNumber" value={formValue.bankAccountNumber} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter account holder name *' invalid>
            <MDBInput type="text" label="Bank Account Holder Name *" id="accountName" name="accountName" value={formValue.accountName} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter branch name' invalid>
            <MDBInput type="text" label="Branch Name" id="branchName" name="branchName" value={formValue.branchName} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter branch location' invalid>
            <MDBInput type="text" label=" Branch Location" id="branchLocation" name="branchLocation" value={formValue.branchLocation} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem id="state" name="state" className='col-md-12' feedback='Please select state' invalid>
            <select className="p-2 col-md-12 rounded" value={formValue.state} onChange={e => onSelectState(e.target.value)} required>
              {states && states.map((state) => (
                <option key={state.id} value={state.stateName} >{state.stateName}</option>
              ))}
            </select>

          </MDBValidationItem>

          {/* <MDBValidationItem feedback='Please enter state' invalid>
            <MDBInput type="text" label="Branch state" id="state" name="state" value={formValue.state} onChange={onChange} required />
          </MDBValidationItem> */}
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enterIFSC code' invalid>
            <MDBInput type="text" label="IFSC code" id="ifscCode" name="ifscCode" value={formValue.ifscCode} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        {/* <div className="form-outline mb-4">
          <input type="text" id="form12" className="form-control" />
          <label className="form-label" htmlFor="form12">
            Email
          </label>
        </div> */}

        <div className="d-flex">
          <div className="mb-4 col-4">Account Type</div>
          <MDBValidationItem feedback='Please select account type' invalid>
            {accountTypes && accountTypes.map((type, index) => (
              <MDBRadio key={type.id} name="accountType" id={type.accountType} label={type.accountType} value={type.accountType} checked={type.accountType === formValue.accountType} onChange={onChange} inline required />
            ))}
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please select account type' invalid>
            <MDBInput type="number" label="Balance" id="balance" name="balance" value={formValue.balance} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        {/* <div className="form-outline mb-4">
          <input type="text" id="form12" className="form-control" />
          <label className="form-label" htmlFor="form12">
            Gateway
          </label>
        </div> */}
        <div className="text-center">
          <MDBBtn type='submit'>SUBMIT</MDBBtn>
        </div>
      </MDBValidation>
    </div>
  )
}

export default BankDetails
