import React from "react"
import {Link} from 'react-router-dom'
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
} from "mdb-react-ui-kit"

export default function Footer() {
  return (
    <MDBFooter
      className="text-center text-white fixed-bottom"
      style={{backgroundColor: "#5f017d"}}
    >
      <MDBContainer className="p-0 pb-0">
        <section className="my-2 d-flex flex-nowrap justify-content-center">
          <div className="flex-grow-1">
            <Link to="/MainPage" className="m-3 fs-3 text-white" href="#!" role="button">
              <MDBIcon fas icon="home" />
            </Link>
          </div>

          <div className="flex-grow-1">
          <Link to="/Flats" className="m-3 fs-3 text-white" href="#!" role="button">
            <MDBIcon far icon="building" />
          </Link>
          </div>

          <div className="flex-grow-1"><a className="m-3 fs-3 text-white" href="#!" role="button">
            <MDBIcon fas icon="wrench" />
          </a>
          </div>

          <div className="flex-grow-1">
          <a className="m-3 fs-3 text-white" href="#!" role="button">
            <MDBIcon fab icon="instagram" />
          </a>
          </div>

          <div className="flex-grow-1">
            <a className="m-3 fs-3 text-white" href="#!" role="button">
            <MDBIcon fas icon="calculator" />
          </a>
          </div>

          <div className="flex-grow-1"><a className="m-3 fs-3 text-white" href="#!" role="button">
            <MDBIcon fas icon="user-friends" />
          </a>
          </div>

          <div className="flex-grow-1"> <a className="m-3 fs-3 text-white" href="#!" role="button">
            <MDBIcon fas icon="clipboard-check" />
          </a>
          </div>

        </section>
      </MDBContainer>
    </MDBFooter>
  )
}
