import React from "react"

// COMPONENT IMPORTS

import Document from "./Document"

// CSS IMPORTS
import "./Society.css"

const Documents = () => {
  return (
    <div className="p-3 society">
      
      <Document file="Montly Statement" description="It is a long established fact that a reader will be distracted. "/>
      <Document file="Intrest Statement" description="It is a long established fact that a reader will be distracted. "/>
      <Document file="Credit Statement" description="It is a long established fact that a reader will be distracted. "/>

      <div className="text-center mt-5">
        <button className="btn btn-primary">
          <span className="">Add</span>
        </button>
      </div>
    </div>
  )
}

export default Documents
