import React from "react"

// Component Import's
import AddEditPropertyForm from "./Common/AddEditPropertyForm"

// CSS Import's
import "./Common/AddEditPropertyForm.css"

const CareTakerFrom = () => {
  return (
    <div className="addEditPropertyForm">
        <AddEditPropertyForm title="Caretaker Details" userId={3} />
    </div>
  )
}

export default CareTakerFrom
