import React from "react"
import Tile from "./Tile"
import Title from '../common/Title'
import {MDBContainer, MDBRow, MDBCol} from "mdb-react-ui-kit"

import "./ClinetDues.css"

const ClientDues = () => {

  const data = [
    { id: 1, roomno: "105", ownerTitle: "Owner", owner: "Lalit Kumar", tenentTitle: "Tenent", tenent: "Kamal", due: "15,000.00", bgColor: "#ff928e" },
    { id: 2, roomno: "106", ownerTitle: "Owner", owner: "Ratan Rao", tenentTitle: "Tenent", tenent: "Kesav", due: "5,700.00", bgColor: "#939df2"},
    { id: 3, roomno: "107", ownerTitle: "Owner", owner: "Lathi Pachi", tenentTitle: "Tenent", tenent: "Ram Raj", due: "29,000.00", bgColor: "#49d391"},
    { id: 4, roomno: "105", ownerTitle: "Owner", owner: "Rajesh", tenentTitle: "Tenent", tenent: "Praveen", due: "15,000.00", bgColor: "#ff928e"},
    { id: 5, roomno: "105", ownerTitle: "Owner", owner: "Kiran Kumar", tenentTitle: "Tenent", tenent: "Madan", due: "1,000.00", bgColor: "#49d391"},
  ];

  return (
    <div className="p-2">
      <Title title="CLIENT DUES"/>
      <MDBContainer className="clientDues d-flex">
      {
        data && data.map((item) => (
          <Tile key={item.id} item={item}/>    
        ))
      }
    </MDBContainer>
    </div>
  )
}

export default ClientDues
