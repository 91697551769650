import React from "react"
import {
    MDBIcon
  } from 'mdb-react-ui-kit';

import './Banner.css';
import { useSelector } from "react-redux";

const Banner = () => {
  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);
  const facilityId = useSelector((state) => state?.appSlice?.facilityId);
  const fac = facilities.filter(f => f._id == facilityId);
  const facility = fac.length > 0 ? fac[0] : {}
  //const facility = useSelector((state) => state?.appSlice?.facility);
  return (
    <div className="d-flex justify-content-center align-items-center p-4 propertyBanner">
      <div className="itemImg me-4">
        <img src="https://mdbootstrap.com/img/new/slides/041.webp" />
      </div>
      <div className="caption ">
        <div className="icon">
          <MDBIcon fas icon="map-marker-alt" />
        </div>
        <div className="ms-2">
          <h5>{facility?.address[0]?.address1}</h5>
          <small className="d-block">{facility?.address[0]?.address2}, {facility?.address[0]?.city?.city?.CityName}.</small>
          <small className="d-block"> {facility?.address[0]?.city?.district?.DistrictName}, {facility?.address[0]?.city?.state?.stateName}</small>
          <small className="d-block"> {facility?.address[0]?.city?.country?.countryName}, {facility?.address[0]?.ziperCode}</small>
          <small className="d-block">{facility?.no_of_units} Flats</small>
        </div>
      </div>
    </div>
  )
}

export default Banner
