import ConfigService from './ConfigService';
import HttpService from "./HttpService";
import userProfile from '../common/userProfile';

export default class APIService {

    static replace(path) {
        let args = Array.prototype.slice.call(arguments, 1);
        let count = -1;
        return path.replace(/:[a-zA-Z?]+/g, function (match) {
            count += 1;
            return args[count] !== undefined ? args[count] : match;
        });
    };

    static queryParams(obj) {
        const keyValuePairs = [];
        for (const key in obj) {
            keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));
        }
        return keyValuePairs.join('&');
    }

    static getUserDetails() {
        return new Promise((resolve, reject) => {
            return HttpService.get(ConfigService.getUserUrl)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getUserByMobile(mobile) {
        return new Promise((resolve, reject) => {
            let url = ConfigService.getUserByMobileUrl;
            if (mobile) { url = url + `?mobile=${mobile}` }

            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getFacility = (facilityId) => {
        return new Promise((resolve, reject) => {
            let url = ConfigService.facilityApiUrl;
            if (facilityId) { url = url + `?id=${facilityId}` }
            return HttpService.get(url)
                .then(resp => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error);
                    reject(error);
                })
        })
    }

    static getFacilities = (payload) => {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.getFacilitiesApiUrl, payload)
                .then(resp => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error);
                    reject(error);
                })
        })
    }

    static getBlocks = (facilityId) => {
        return new Promise((resolve, reject) => {
            let url = ConfigService.blockApiUrl;
            if (facilityId) { url = url + `?facility_id=${facilityId}` }
            return HttpService.get(url)
                .then(resp => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static saveBlock(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.blockApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from save block');
                    reject(error);
                })
        })
    }

    static updateBlock(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.put(ConfigService.blockApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from update block');
                    reject(error);
                })
        })
    }

    static getRoads = (facilityId) => {
        return new Promise((resolve, reject) => {
            let url = ConfigService.getRoadApiUrl;
            if (facilityId) { url = url + `?facility_id=${facilityId}` }
            return HttpService.get(url)
                .then(resp => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error);
                    reject(error);
                })
        })
    }

    static saveRoad(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.roadApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from save roads');
                    reject(error);
                })
        })
    }

    static updateRoad(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.put(ConfigService.roadApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getUnitTypes(facilityId) {
        return new Promise((resolve, reject) => {
            let url = ConfigService.unitTypeApiUrl;
            if (facilityId) { url = url + `?facility_id=${facilityId}` }

            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static saveUnitType(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.unitTypeApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static updateUnitType(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.put(ConfigService.unitTypeApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getUnits(params) {
        return new Promise((resolve, reject) => {
            const queryString = APIService.queryParams(params);
            let url = ConfigService.unitsApiUrl + `?${queryString}`;
            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static saveUnits(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.unitsApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static updateUnits(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.put(ConfigService.unitsApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getFacilityUnit(unitId) {
        let url = APIService.replace(ConfigService.getFacilityUnitApiUrl, unitId);
        return new Promise((resolve, reject) => {
            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from get facility Unit');
                    reject(error);
                })
        })
    }

    static saveFacilityUnitsWithUser(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.saveFacilityUnitsWithUserApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from save FacilityUnitsWithUser');
                    reject(error);
                })
        })
    }

    static updateFacilityUnits(payload) {
        return new Promise((resolve, reject) => {
            let url = APIService.replace(ConfigService.updateBlockApiUrl, payload.unitId);

            return HttpService.patch(url, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from update Facility Units');
                    reject(error);
                })
        })
    }

    static deleteFacilityUnits(payload) {
        return new Promise((resolve, reject) => {
           // let url = APIService.replace(ConfigService.updateFacilityUnitsApiUrl, unitId);

            return HttpService.patch(ConfigService.unitsApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from delete Facility Units');
                    reject(error);
                })
        })
    }

    static getAllFacilityUnitUserTypes() {
        return new Promise((resolve, reject) => {
            return HttpService.get(ConfigService.getAllFacilityUnitUserTypeApiUrl)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from get getAllFacilityUnitUserTypes');
                    reject(error);
                })
        })
    }

    static getAllMtricTypes() {
        return new Promise((resolve, reject) => {
            return HttpService.get(ConfigService.getAllMtricTypeApiUrl)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from get getAllMtricTypes');
                    reject(error);
                })
        })
    }

    static getAllAccountTypes() {
        return new Promise((resolve, reject) => {
            return HttpService.get(ConfigService.getAllAccountTypeApiUrl)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from get getAllAccountTypes');
                    reject(error);
                })
        })
    }

    static getSocieties(facilityId) {
        return new Promise((resolve, reject) => {
            let url = ConfigService.societyApiUrl;
            if (facilityId) { url = url + `?facility_id=${facilityId}` }

            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static saveSociety(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.societyApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from saveFacilitySociety');
                    reject(error);
                })
        })
    }

    static updateSociety(payload) {
        return new Promise((resolve, reject) => {
            let url = APIService.replace(ConfigService.societyApiUrl, payload);
            return HttpService.put(url, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from saveFacilitySociety');
                    reject(error);
                })
        })
    }

    static getAllFacilityTypes() {
        return new Promise((resolve, reject) => {
            return HttpService.get(ConfigService.getAllFacilityTypeApiUrl)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from saveFacilitySociety');
                    reject(error);
                })
        })
    }

    static saveSocietyBank(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.societyBankApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static updateSocietyBank(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.put(ConfigService.societyBankApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getSocietyBanks(societyId) {
        return new Promise((resolve, reject) => {
            let url = ConfigService.societyApiUrl;
            if (societyId) { url = url + `?society_id=${societyId}` }

            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    reject(error);
                })
        })
    }

    static getSocietyCommitte(payload) {
        return new Promise((resolve, reject) => {
            let url = APIService.replace(ConfigService.searchSocietyCommitteApiUrl, payload.societyId);
            if (payload.roletype) { url = url + `?roletype=${payload.roletype}` }                     //ConfigService.searchSocietyCommitteApiUrl + `${payload.societyId}?roleType=${payload.roletype}`     // APIService.replace(ConfigService.searchSocietyCommitteApiUrl, payload.societyId);
            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from getSocietyCommitte');
                    reject(error);
                })
        })
    }

    static saveSocietyCommitte(payload) {
        return new Promise((resolve, reject) => {
            return HttpService.post(ConfigService.saveSocietyCommitteApiUrl, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from saveSocietyCommitte');
                    reject(error);
                })
        })
    }

    static updateSocietyCommitte(payload) {
        return new Promise((resolve, reject) => {
            let url = APIService.replace(ConfigService.updateSocietyCommitteApiUrl, payload.id);
            return HttpService.patch(url, payload)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from updateSocietyCommitte');
                    reject(error);
                })
        })
    }

    static getSocietyRoles(payload) {
        return new Promise((resolve, reject) => {
            let url = APIService.replace(ConfigService.searchSocietyRolesApiUrl, payload.societyId);

            return HttpService.get(url)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from getSocietyCommitte');
                    reject(error);
                })
        })
    }

    static getAllStates() {
        return new Promise((resolve, reject) => {
            return HttpService.get(ConfigService.getStatesApiUrl)
                .then((resp) => {
                    if (resp) {
                        resolve(resp);
                    } else {
                        reject(resp);
                    }
                }, error => {
                    console.log(error, 'error from saveFacilitySociety');
                    reject(error);
                })
        })
    }

    static refreshFacilities(callBack){
        let facilities = userProfile.getFacilities();
        if (facilities?.length > 0) {
            const facilityIds = facilities?.map((f) => f._id);
            let payload = {
              facility: facilityIds
        }          
        return this.getFacilities(payload).then((res => {
            console.log("fecilities from api",res);
            if(res?.facilityData?.length > 0 ){
              userProfile.setFacilities(res.facilityData);
              if(callBack) callBack(res);
              return (res.facilityData)
            }
            
          }), ((error) => {
            console.log("error::", error)
            return (error);
          }))
    

        }
    }
}

