import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import Society from "./Society"
import { useSelector } from "react-redux";
import APIService from '../../Services/APIService';

const Index = () => {

  const facility = useSelector((state) => state?.appSlice?.facility);

  const [societies, setSocieties] = useState([]);

  const getSocieties = () => {
    APIService.getSocieties(facility.id).then((res => {
      setSocieties(res);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  useEffect(() => {
    getSocieties();
  }, []);

  return (
    <div className="pb-3">
      {facility?.society && facility?.society.map((society, index) => (
        <Society
          key={society._id}
          index={index}
          society={society}
        />
      ))}
      {facility?.society.length == 0 && <p className="text-center">No Societies to show</p>}
      <div className="text-center my-3">
        <Link to="/AddEditSociety" state={{ society: {} }} className="btn btn-primary">
          ADD SOCIETY
        </Link>
      </div>
    </div>
  )
}

export default Index
