import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// Component Import's
import Title from "../../common/Title"
import AddEditPropertyForm from "./Common/AddEditPropertyForm";
import APIService from "../../Services/APIService";

// CSS Import's
import "./Common/AddEditPropertyForm.css"

const CreatePropertyForm = () => {
 
  const [unitUserTypes, setUnitUserTypes] = useState([]);
  const [selecteduserType, setUserType] = useState({});

  const getUnitUserTypes = () => {
    APIService.getAllFacilityUnitUserTypes().then((res => {
      console.log("res::", res)
      setUnitUserTypes(res);
      setUserType(res[0])
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  useEffect(() => {
    getUnitUserTypes();
  }, []);


  return (
    <div className="addEditPropertyForm">

      <AddEditPropertyForm title="Owner Details"  />

      {/* <div className="d-flex jutify-content-between p-5 py-2">
          <div className="col-9">
            <Title title="Tenent Details" />
          </div>
          <div className="col-3 text-end view">
            <Link to="/TenentForm" className="view">
              View
            </Link>
          </div>
        </div>

        <div className="d-flex jutify-content-between p-5 py-2">
          <div className="col-9">
            <Title title="Caretaker Details" />
          </div>
          <div className="col-3 text-end view" >
            <Link to="/CareTakerFrom" className="view">
              View
            </Link>
          </div>
        </div>

      <div className="d-flex jutify-content-between p-5 py-2">
        <div className="col-9 d-flex">
          <Title title="Preffered User Details" /> <span> &nbsp; *</span><br />

        </div>
        <div className="col-3 text-end view">
          <select className="p-2 rounded-3">
          {unitUserTypes &&
                  unitUserTypes.map((item) => (
                    <option key={item.id} value={item.id} >{item.name}</option>
                  ))}
          </select>
        </div>
      </div> */}

      {/* <option>Owner</option>
                <option>Tenent</option>
                <option>Caretaker</option> */}
    </div>
  )
}

export default CreatePropertyForm
