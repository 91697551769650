import React from 'react'
import {Link} from "react-router-dom"
import {
    MDBListGroup,
    MDBListGroupItem,
    MDBIcon,
    MDBBtn,
    MDBContainer,
    MDBRow,
    MDBCol,
  } from "mdb-react-ui-kit"

  import "./Society.css"

const Document = ({file, description}) => {
  return (
    <div className='pb-5 society'>
    <MDBListGroup flush style={{minWidth: "22rem"}}>
        <MDBListGroupItem>
          <MDBIcon far icon="file-alt" className="me-2" /> 
          <span>{file}</span>
          <Link to="/AddEditDocument">
            <MDBIcon far icon="edit" className="float-end" />
          </Link>
        </MDBListGroupItem>
        <MDBListGroupItem className='d-flex'>
          <MDBIcon far icon="copy" className="me-2" /> 
          <span>{description}</span>
          <Link to="">
            <MDBIcon fas icon="file-download" className="float-end" />
          </Link>
        </MDBListGroupItem>
      </MDBListGroup>
      </div>
  )
}

export default Document