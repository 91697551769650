import React from 'react'
import "./commonStyles.css"

const Title = ({title}) => {
  return (
    <h4 className='tileTitle'>{title}</h4>
  )
}

export default Title
