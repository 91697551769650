import React, {useEffect} from "react"

// Components Import's
import Carousel from "../../Carousel"
import NewVisitor from "../../NewVisitor"
import Approvals from "../../Approvals/Approvals"
import ClientDues from "../../ClientDues/ClientDues"
import Communications from "../../Communications/Communications"
import Banner from './Banner'
import MyPropertyInfo from "./MyPropertyInfo";

const Main = () => { 

  return (
    <>
    <Banner />
    <div className="py-3" style={{background: "#FFD0E7"}}>
    <Communications />
    </div>
      <ClientDues />
      <Approvals />
      <MyPropertyInfo />
    </>
  )
}

export default Main
