import React, { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MDBBtn, MDBAlert } from 'mdb-react-ui-kit';

// CSS Import's
import "./Flats.css"

// Component Imports

import FlatTab from "./FlatTab"
import PropertyListItem from "./PropertyListItem";
import APIService from '../../Services/APIService';
import Roads from "./Roads";
import Loader from "../../common/Loader";
import userProfile from "../../common/userProfile";
import Permission from "../../Services/Permission";
import { getPermission } from "../../Services/PermissionUtil";
import { getFacilityData } from "../../../redux-store/facilitySlice";

const Flats = () => {

  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);
  const facilityId = useSelector((state) => state?.appSlice?.facilityId);
  const fac = facilities.filter(f => f._id == facilityId);
  const facility = fac.length > 0 ? fac[0] : {}

  const [facilityUnits, setFacilityUnits] = useState([]);
  const [selectedBlockId, setSelectedBlock] = useState(0);
  const [selectedRoadId, setSelectedRoad] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  const [blocks, setBlocks] = useState([]);

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: '',
    color: ''
  });

  const [roads, setRoads] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const triggerItem = useRef(null);

  useEffect(() => {
    APIService.refreshFacilities((res) => {
      dispatch(getFacilityData(res?.facilityData));
    });
  }, []);

  useEffect(() => {
    setBlocks(facility?.blocks);
    setFacilityUnits(facility?.units);
    setRoads(facility?.roads);
    //console.log(userProfile.getFacilityId());
  }, [facilities]);

  const routeChange = () => {
    //let path = `/CreatePropertyForm`
    const unitCreate = getPermission(facility?._id, Permission.unit_create);
    if (unitCreate) {
      let path = `/AddEditPropertyForm`
      navigate(path, { state: { property: {} } })
    } else {
      setAlert({ show: true, message: "Do not have Permission to Create Unit..", color: "danger" });
      setTimeout(() => {
        setAlert({ show: false, message: "", color: "" })
      },
        1500);
      //alert('Do not have Permission to Create Unit..')
    }
  }

  const getFacilityUnits = (blockId = 0, roadId = 0, name = '') => {
    let payload = {}

    if (blockId !== 0) payload.block_id = blockId;
    if (roadId !== 0) payload.road_id = roadId;
    if (name) payload.unit_name = name;

    console.log('payload', payload)

    let units = facility?.units.filter(item => {
      for (let key in payload) {
        console.log(item[key], payload[key], key);
        if (item[key] === undefined || !item[key].includes(payload[key]))
          return false;
      }
      return true;
    });

    if (units.length > 0) {
      setFacilityUnits(units);
    } else {
      setFacilityUnits([]);
      setAlert({ show: true, message: "No Units for selected Block", color: "warning" });
      setTimeout(() => {
        setAlert({ show: false, message: "", color: "" })
      },
        1500);
    }

  };

  const selectBlock = (id) => {
    console.log(id);
    setSelectedBlock(id);
    getFacilityUnits(id, 0);
    setSelectedRoad(0);
  }

  const selectRoad = (id) => {
    console.log(id);
    setSelectedRoad(id);
    getFacilityUnits(selectedBlockId, id);
  }

  const saveInput = (val) => {
    getFacilityUnits(selectedBlockId, selectedRoadId, val);
  }

  const processChange = userProfile.debounce((e) => saveInput(e.target.value), 500);

  return (
    <div className="Container flats">
      {showLoader ?
        <Loader /> :
        <>
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center pt-4 pb-5 search">
              <small className="d-block mx-3  fw-bold">UNITS</small>
              <form>
                <div className="form-outline">
                  <input
                    type="search"
                    id="form1"
                    className="form-control bg-white radius-5"
                    placeholder="Search"
                    aria-label="Search"
                    onChange={processChange}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="row">
            <div className="col px-5">
              <div className="d-flex justify-contentcenter filterTabs">
                <FlatTab title="All" selected={selectedBlockId == 0} onSelectBlock={() => selectBlock(0)} />

                {blocks && blocks.map((item) => (
                  <FlatTab title={item.block_name} key={item._id} selected={selectedBlockId == item._id} onSelectBlock={() => selectBlock(item._id)} />
                ))}
              </div>
              {blocks && facility?.facility_type?.facilityName == 'Colony' &&
                <Roads roadList={roads} setSelectedRoadId={(roadId) => selectRoad(roadId)} roadId={selectedRoadId} />
              }
            </div>
          </div>
          <div className="row">
            <div className="col px-5">
              {facilityUnits &&
                facilityUnits.map((item) => (
                  <PropertyListItem plotNumber={item.unit_name} type={item?.unit_type} block="B - A" key={item._id} property={item} />
                ))}
              {facilityUnits.length == 0 && <p >No Facility Units to show</p>}
              <div className=" text-center mt-3" ref={triggerItem}>
                <button className="btn btn-primary" onClick={routeChange}>Add Unit</button>
              </div>
            </div>
          </div>
        </>}
      <MDBAlert
        color={alert.color}
        autohide
        width={800}
        position='top-right'
        offset={50}
        delay={2000}
        appendToBody
        triggerRef={triggerItem}
        show={alert.show}
      >
        {alert.message}
      </MDBAlert>
    </div>
  )
}

export default Flats
