import React from "react"
import {MDBBtn, MDBIcon, MDBRow, MDBCol} from "mdb-react-ui-kit"

const Tile = ({item, type}) => {
  return (
    <MDBCol className={`tile mx-1`} style={{backgroundImage: `url(${item.image})`, backgroundSize: 'cover'}} >
        <div className="p-2 py-4 float-end" style={{background: `${item.bgColor}`, height: "100%", opacity: ".8"}} >
          <h6 className="m-0"> {item.price} </h6>
          <small> {item.description} </small>
        </div>
    </MDBCol>
  )
}

export default Tile
