import React from "react"
import "./Otp.css"

import {useNavigate} from "react-router-dom"

const Otp = ({navbarHide, logged}) => {
  const navigate = useNavigate()

  const routeChange = () => {
    let path = `/CreateAccount`
    navigate(path)
    navbarHide()
  }

  return (
    <div
      className="container d-flex flex-column justify-content-center align-items-center px-5 otp"
      style={{height: "88vh"}}
    >
      <h2 className="title fw-bold fs-4">OTP</h2>
      <img src="/images/otp-verify.png" alt="" className="my-2" />
      <div className="text-center">
        <p className="fs-5 fw-bold">Verification Code</p>
        <small>We have sent the code verification to Your Mobile Number</small>
        <p className="fs-5 fw-bold m-0 phoneNumber">+91 9548736214</p>
        <div className="container mb-2">
          <div className="row">
            <div className="col-3 text-center p-2">
              <input type="text" min="1" max="1"/>
            </div>
            <div className="col-3 text-center p-2">
              <input type="text" />
            </div>
            <div className="col-3 text-center p-2">
              <input type="text" />
            </div>
            <div className="col-3 text-center p-2">
              <input type="text" />
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-primary btn-block p-3 rounded-pill fs-6"
          onClick={routeChange}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default Otp
