import React, { useState } from "react"
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import APIService from "../../Services/APIService";
import moment from 'moment';
import Alert from '../../common/Alert';

import {
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBValidation,
  MDBDatepicker,
  MDBValidationItem,
} from 'mdb-react-ui-kit';

// Component Imports
import Title from "../../common/Title"

// CSS IMPORTS
import "./Society.css"

const AddEditSociety = () => {
  const current = moment(new Date()).format("yyyy-MM-DD");

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });

  const location = useLocation();
  const navigate = useNavigate()

  const { society } = location.state;

  const facility = useSelector((state) => state?.appSlice?.facility)

  const [formValue, setFormValue] =
    useState({
      societyName: society?.societyName ? society.societyName : '',
      description: society?.description ? society?.description : '',
      regNo: society?.regNo ? society?.regNo : '',
      regDate: society?.regDate ? society?.regDate : '',
      regAuthority: society?.regAuthority ? society?.regAuthority : '',
      validTill: society?.validTill ? moment(society?.validTill).format("yyyy-MM-DD") : ''
    });

  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const saveSociety = async (e) => {
    e.preventDefault();

    let payload = {
      "facility_id": facility.id,
      "regAdddressId": facility?.address ? facility?.address.id : '',
      "society_name": formValue.societyName,
      "society_description": formValue.description,
      "society_number": formValue.regNo,
      "regDate": current,
      "regAuthority": formValue.regAuthority,
      "validTill": "2022-08-30"
    }

    if (!formValue.societyName || !formValue.description || !formValue.regNo || !formValue.regAuthority) return;

    if (society?.id) {
      payload.societyId = society?.id,
        updateSociety(payload);
    } else {
      createSociety(payload);
    }
  };

  const createSociety = async (payload) => {
    APIService.saveSociety(payload).then((res => {
      console.log("res::", res)
      setAlert({ show: true, message: "Successlly Saved..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const updateSociety = async (payload) => {
    APIService.updateSociety(payload).then((res => {
      console.log("res::", res)
      setAlert({ show: true, message: "Updated Successlly..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  return (
    <div className="p-3 society">
      {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
      <MDBValidation className='row g-3' onSubmit={saveSociety}>
        <div className="py-3">
          <Title title="ADD EDIT SOCIETY" />
        </div>
        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter society name' invalid>
            <MDBInput type="text" label="Society Name" id="societyName" name="societyName" value={formValue.societyName} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter society description' invalid>
            <MDBInput type="text" label="Description" id="description" name="description" value={formValue.description} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter Registration Number' invalid>
            <MDBInput type="text" label="Registration Number" id="regNo" name="regNo" value={formValue.regNo} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4 d-flex align-items-center">
          <div className="col-5">
            <strong>Registration Date</strong>
          </div>
          <div className="ms-2 col-7 text-center">{current}</div>
        </div>

        <div className="form-outline mb-4">
          <MDBValidationItem feedback='Please enter Registration Authority' invalid>
            <MDBInput type="text" label="Registration Authority" id="regAuthority" name="regAuthority" value={formValue.regAuthority} onChange={onChange} required />
          </MDBValidationItem>
        </div>

        <div className="form-outline mb-4 d-flex align-items-center">
          <div className="col-3">
            <strong>Valid Upto</strong>
          </div>

          <div className="mx-2 col-7">
            <MDBValidationItem feedback='Please enter Valid Upto Date' invalid>
              <MDBDatepicker inline format='yyyy-mm-dd' title='Valid Till' value={formValue.validTill} required />
              {/* <MDBInput type="text" label="Valid Upto Date" id="validTill" name="validTill" value={formValue.validTill} onChange={onChange} required /> */}
            </MDBValidationItem>
          </div>

        </div>

        <div className="text-center mt-5">
          <MDBBtn type='submit'>SUBMIT</MDBBtn>
        </div>
      </MDBValidation>
    </div>
  )
}

export default AddEditSociety
