
export default class ConfigService {
  
  static baseApiUrl = 'api/v1';
  
  static getUserUrl = this.baseApiUrl + '/user';

  static getUserByMobileUrl = this.baseApiUrl + '/mobile';

  static facilityApiUrl = this.baseApiUrl + '/facility';

  static getFacilitiesApiUrl = this.baseApiUrl + '/facilities';

  static blockApiUrl = this.baseApiUrl + '/block';

  static roadApiUrl = this.baseApiUrl + '/road';

  static unitTypeApiUrl = this.baseApiUrl + '/unit/type';

  static unitsApiUrl = this.baseApiUrl + '/unit';

  static updateFacilityUnitsApiUrl = this.baseApiUrl + '/unit/:unitId';
  static getFacilityUnitApiUrl = this.baseApiUrl + '/unit/user/:unitId';
  static saveFacilityUnitsWithUserApiUrl = this.baseApiUrl + '/unit/user';

  static societyApiUrl = this.baseApiUrl + '/society';

  static societyBankApiUrl = this.baseApiUrl + '/society/banks';

  static saveSocietyCommitteApiUrl = this.baseApiUrl + '/society-committe';
  static updateSocietyCommitteApiUrl = this.baseApiUrl + '/society-committe/:id';
  static searchSocietyCommitteApiUrl = this.baseApiUrl + '/society-committe/search/:societyId';
  static searchSocietyRolesApiUrl = this.baseApiUrl + '/society-role/search/:societyId';

  static getAllMtricTypeApiUrl = this.baseApiUrl + '/metricType';
  static getStatesApiUrl = this.baseApiUrl + '/state?countryId=IN';
  static getAllFacilityTypeApiUrl = this.baseApiUrl + '/facilitytype';
  static getAllAccountTypeApiUrl = this.baseApiUrl + '/ac_type';
  static getAllFacilityUnitUserTypeApiUrl = this.baseApiUrl + '/unit/type/users';

}
