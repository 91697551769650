import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  MDBValidation,
  MDBValidationItem,
  MDBInput,
  MDBBtn
} from 'mdb-react-ui-kit';
import APIService from '../../Services/APIService';
import Alert from '../../common/Alert';

const AddEditUnitForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { selectedUnitType, facilityId } = location?.state;
  console.log(location.state);

  const [metricTypes, setMetricTypes] = useState([]);
  const facilities = useSelector((state) => state?.facilitySlice?.facilityData);
 // const facilityId = useSelector((state) => state?.appSlice?.facilityId);
  const fac = facilities.filter(f => f._id == facilityId);
  const facility = fac.length > 0 ? fac[0] : {}
  
  const [formValue, setFormValue] =
    useState({
      unitName: selectedUnitType?.unit_type ? selectedUnitType?.unit_type : '',
      weightage: selectedUnitType?.weight ? selectedUnitType?.weight : '',
      totalArea: selectedUnitType?.total_areas ? selectedUnitType?.total_areas : '',
      metricType: selectedUnitType?.metric_type ? selectedUnitType?.metric_type : 'sft',
    });

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    className: ''
  });


  const onChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const updateUnitType = async () => {
    let payload = {
      "id": selectedUnitType?._id,
      "unit_type": formValue.unitName,
      "total_areas": formValue.totalArea,
      "weight": formValue.weightage,
      "metric_type": formValue.metricType,
    }
    APIService.updateUnitType(payload).then((res => {
      APIService.refreshFacilities().then((res => {
        console.log(res, 'refreshcall');
      })), ((error) => {
        console.log("error::", error)
      })
      setAlert({ show: true, message: "Updated Successlly..", className: "success" });
      setTimeout(() => {
        navigate(-1)
        setAlert({ show: false, message: "", className: "" })
      },
        1500);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const createUnitType = async () => {
    if (formValue.unitName && formValue.weightage && formValue.totalArea && formValue.metricType) {
      let payload = {
        "facility_id": facilityId,
        "unit_type": formValue.unitName,
        "total_areas": formValue.totalArea,
        "weight": formValue.weightage,
        "metric_type": formValue.metricType

      }
      APIService.saveUnitType(payload).then((res => {
        console.log("save unit type res :",res);
        
        APIService.refreshFacilities().then((res => {
          console.log(res, 'refreshcall');
        })), ((error) => {
          console.log("error::", error)
        })
        setAlert({ show: true, message: "Successlly Saved..", className: "success" });
        setTimeout(() => {
          navigate(-1)
          // window.location.reload();
          setAlert({ show: false, message: "", className: "" })
        },
          1500);
      }), ((error) => {
        console.log("error::", error)
      }))

    }
  };

  const saveUnitType = async (e) => {
    e.preventDefault();
    if (!formValue.unitName || !formValue.weightage || !formValue.totalArea || !formValue.metricType) return;

    if (formValue.unitName && formValue.weightage && formValue.totalArea && formValue.metricType && selectedUnitType?._id) {
      console.log('call for update api');
      updateUnitType();
    } else {
      createUnitType();
      console.log('call for create api');
    }
  };

  const onSelectMetric = (value) => {
    setFormValue({ ...formValue, metricType: value });
  }

  const getMetricTypes = () => {
    APIService.getAllMtricTypes().then((res => {
      setMetricTypes(res?.data);
    }), ((error) => {
      console.log("error::", error)
    }))
  };

  const nameDuplacteCheck = () => {
    console.log('on blur called');
    if (selectedUnitType?._id) return;
    let nameExist = facility?.unitTypes && facility?.unitTypes.filter(unit => unit.unit_type == formValue.unitName);
    console.log(nameExist);
    if (nameExist?.length > 0) {
      setAlert({ show: true, message: "Duplicate UnitType name entered.. Try with other Name..", className: "warning" });
      setTimeout(() => { setAlert({ show: false, message: "", className: "" }) }, 1500);
      //alert('Duplicate UnitType name entered.. Try with other Name..');
      return;
    }
  }

  useEffect(() => {
    getMetricTypes();
  }, []);

  return (
    <div className="p-5">
      {alert?.show && <Alert mesg={alert?.message} className={alert?.className} />}
      <MDBValidation className='row g-3' onSubmit={saveUnitType}>
        <MDBValidationItem className='col-md-12' feedback='Please enter UnitType Name.' invalid>
          <MDBInput
            value={formValue.unitName}
            name='unitName'
            onChange={onChange}
            id='validationCustom03'
            required
            label='Unit Type Name *'
            onBlur={nameDuplacteCheck}
          />
          <small style={{ fontSize: ".7rem", color: "#999" }}>** example: Single Bedroom, Double Bedroom</small>
        </MDBValidationItem>

        <MDBValidationItem className='col-md-12' feedback='Please enter Weightage.' invalid>
          <MDBInput
            value={formValue.weightage}
            name='weightage'
            onChange={onChange}
            id='validationCustom03'
            required
            label='Weightage *'
          />
          <small style={{ fontSize: ".7rem", color: "#999" }}>
            *Unit to be used while calculating cgarges<br />
            **Example: If 1 Unit =   1000 then 1.2 is calculated as   1200. 1.5 is calculated
            as   1500..
          </small>
        </MDBValidationItem>

        <MDBValidationItem className='col-md-12' feedback='Please enter Totalarea.' invalid>
          <MDBInput
            value={formValue.totalArea}
            name='totalArea'
            onChange={onChange}
            id='validationCustom03'
            required
            label='Totalarea *'
            type='number'
          />
          <small style={{ fontSize: ".7rem", color: "#999" }}>Square feet</small>
        </MDBValidationItem>
        <MDBValidationItem className='col-md-12' feedback='Please select metric Type.' invalid>
          <select className="p-2 col-md-12 rounded" onChange={e => onSelectMetric(e.target.value)}>
            {metricTypes && metricTypes.map((item) => (
              <option key={item.metric_type_name} value={item.metric_type_name} >{item.metric_type_name}</option>
            ))}
            {/* <option value={"sft"}>Square feet</option>
            <option value={"sqy"}>Square Yard</option>
            <option value={"sqm"}>Square Meter</option> */}
          </select>

        </MDBValidationItem>

        <div className="text-center">
          <MDBBtn type='submit'>Submit</MDBBtn>
        </div>
      </MDBValidation>
      {/*
      <div>
        <div className="form-outline mb-3">
          <input type="text" id="form12" className="form-control" />
          <label className="form-label" htmlFor="form12">
            Unit Type Name *
          </label>
          <small style={{ fontSize: ".7rem", color: "#999" }}>** example: Single Bedroom, Double Bedroom</small>
        </div> 

         <div className="form-outline mb-3">
          <input type="text" id="form12" className="form-control" />
          <label className="form-label" htmlFor="form12">
            Weightage *
          </label>
          <small style={{ fontSize: ".7rem", color: "#999" }}>
            *Unit to be used while calculating cgarges<br />
            **Example: If 1 Unit =   1000 then 1.2 is calculated as   1200. 1.5 is calculated
            as   1500..
          </small>
        </div> 

        <div className="form-outline mb-3">
          <input type="text" id="form12" className="form-control" />
          <label className="form-label" htmlFor="form12">
            Totalarea *
          </label>
          <small style={{ fontSize: ".7rem", color: "#999" }}>Square feet</small>
        </div>

      </div>
      <div className="text-center">
        <button className="btn btn-primary">Submit</button>
      </div> */}
    </div>
  )
}

export default AddEditUnitForm