import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MDBRow, MDBCol, MDBSpinner } from "mdb-react-ui-kit";
import "./index.css";

import Navbar from "./Components/common/Navbar";
import Footer from "./Components/common/Footer";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import Login from "./Components/Pages/LoginPage";
import OTP from "./Components/Pages/Otp";
import CreateAccount from "./Components/Pages/CreateAccount";
import MainPage from "./Components/Pages/Main/Main";
import Properties from "./Components/Pages/Properties/Properties";
import Flats from "./Components/Pages/Properties/Flats";
import CreatePropertyForm from "./Components/Pages/Properties/CreatePropertyForm";
import AddEditPropertyForm from "./Components/Pages/Properties/Common/AddEditPropertyForm";
import TenentForm from "./Components/Pages/Properties/TenentForm";
import CareTakerFrom from "./Components/Pages/Properties/CareTakerFrom";
import AddEditBlock from "./Components/Pages/Properties/AddEditBlock";
import AddEditUnitForm from "./Components/Pages/Properties/AddEditUnitForm";
import SocietyIndex from "./Components/Pages/Society/Index";
import SocietyDetails from "./Components/Pages/Society/SocietyDetails";
import BankDetails from "./Components/Pages/Society/BankDetails";
import Documents from "./Components/Pages/Society/Documents";
import AddEditDocument from "./Components/Pages/Society/AddEditDocument";
import AddEditSociety from "./Components/Pages/Society/AddEditSociety";
import Executives from "./Components/Pages/Society/Executives";
import EditExecutive from "./Components/Pages/Society/EditExecutive";
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import userProfile from "./Components/common/userProfile";
import { updateBlocks, updateFacility } from "./redux-store/appSlice";
import AddEditRoad from "./Components/Pages/Properties/AddEditRoad";
import Alert from './Components/common/Alert'
import Loader from "./Components/common/Loader";
import { updateUserData} from './redux-store/userSlice';
import { getFacilityData} from './redux-store/facilitySlice';

function App() {

  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    let facility = userProfile.getFacility();
    if (facility && facility?._id) {
      dispatch(updateFacility(facility));
    }

    // let blocks = userProfile.getFacilityBlocks();
    // if (blocks && blocks?.length > 0) {
    //   dispatch(updateBlocks(blocks));
    // }

    let facilities = userProfile.getFacilities();
   
    if (facilities && facilities?.length > 0) {
      dispatch(getFacilityData(facilities));
    }

    let user = userProfile.getUserObj();
    if (user && user?.user_id ) {
      dispatch(updateUserData(user));
    }


    const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log(facilities, 'Auth', currentuser, 'user', user)
      console.log(currentuser?.uid && user && user?.user_id && facilities)
      if(currentuser?.uid && user && user?.user_id && facilities.length > 0) {
        setLogged(true);
      }
      setLoading(false);
    })

    return () => {
      unsubscribe()
    }
  }, []);

  const navbarHide = () => {
    setLogged(!logged);
    console.log(logged, "logged");
  };
  return (
    <BrowserRouter>
      <UserAuthContextProvider>
        {isLoading ?
          <Loader />
          :
          <div style={{ marginTop: "58px", marginBottom: "50px" }}>
            {!logged ? (
              <>
                <Routes>
                  <Route
                    path="/"
                    exact
                    element={<Login logged={logged} navbarHide={navbarHide} />}
                  />
                  <Route path="/otp" element={<OTP />} />
                  <Route path="/*" element={<Navigate to="/" />} />
                </Routes>
              </>
            ) : (
              <>
              {/* <Alert mesg="Successlly Saved"/> */}

                <Navbar />
                <Routes>
                  <Route path="/" exact element={<MainPage />} />
                  <Route path="/CreateAccount" element={<CreateAccount />} />
                  <Route path="/MainPage" element={<MainPage />} />
                  <Route path="/Properties" element={<Properties />} />
                  <Route path="/Flats" element={<Flats />} />
                  <Route
                    path="/CreatePropertyForm"
                    element={<CreatePropertyForm />}
                  />
                  <Route
                    path="/AddEditPropertyForm"
                    element={<AddEditPropertyForm />}
                  />
                  <Route path="/TenentForm" element={<TenentForm />} />
                  <Route path="/CareTakerFrom" element={<CareTakerFrom />} />
                  <Route path="/AddEditBlock" element={<AddEditBlock />} />
                  <Route path="/AddEditUnitForm" element={<AddEditUnitForm />} />
                  <Route path="/Society" element={<SocietyIndex />} />
                  <Route path="/SocietyDetails" element={<SocietyDetails />} />
                  <Route path="/BankDetails" element={<BankDetails />} />
                  <Route path="/Documents" element={<Documents />} />
                  <Route path="/AddEditDocument" element={<AddEditDocument />} />
                  <Route path="/AddEditSociety" element={<AddEditSociety />} />
                  <Route path="/Executives" element={<Executives />} />
                  <Route path="/EditExecutive" element={<EditExecutive />} />
                  <Route path="/AddEditRoad" element={<AddEditRoad />} />
                </Routes>
                <Footer />
              </>
            )}
          </div>}
      </UserAuthContextProvider>
    </BrowserRouter>
  );
}

export default App;
