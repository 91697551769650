import React from "react"

// Component Import's
import AddEditPropertyForm from "./Common/AddEditPropertyForm"

// CSS Import's
import "./Common/AddEditPropertyForm.css"

const TenentForm = () => {
  return (
    <div className="addEditPropertyForm">
        <AddEditPropertyForm title="Tenent Details" userId={2} />
    </div>
  )
}

export default TenentForm
