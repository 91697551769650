import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB-BqgyXF-38TCq2FSekP5aUihIEEozmko",
  authDomain: "houeze-web-admin.firebaseapp.com",
  projectId: "houeze-web-admin",
  storageBucket: "houeze-web-admin.appspot.com",
  messagingSenderId: "235837830195",
  appId: "1:235837830195:web:689ce3ebec4485ec549f46",
  measurementId: "G-J9J9SWSN2W",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
